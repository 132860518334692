import { Button, Col, message, Popconfirm, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { calculateTotalAmount, displayFormattedDate, getClientConfig, isEmpty } from '../../generalFunctions';
import useApi from '../../hooks/useApi';
import TableComponent from '../common/table/TableComponent';
import AddRawMaterial from './AddRawMaterial';
import ProcessedEntries from './ProcessedEntries';
import SendToProcess from './SendToProcess';

const RawMaterialTable = (props) => {
    const { purchaseTypes, teaGrades, grades, brands } = getClientConfig();
    const columns = [
        {
            title: "Purchase Type",
            dataIndex: 'purchaseType',
            sorter: true,
            render: (text) => purchaseTypes.find(type => type.id === text).purchaseType
        },
        {
            title: "Received Date",
            dataIndex: 'receivedDate',
            sorter: true,
            render: (text) => displayFormattedDate(text)
        },
        {
            title: "Garden / Auction",
            dataIndex: 'gardenAuctionName',
            sorter: true
        },
        {
            title: "Quantity (bags)",
            dataIndex: 'numberOfBags',
            sorter: true
        },
        {
            title: "Quantity (kg)",
            dataIndex: 'quantityInKg',
            sorter: true
        },
        {
            title: "Kg per Bag",
            dataIndex: 'kgPerBag',
            sorter: true
        },
        {
            title: "Brand",
            dataIndex: 'brandId',
            sorter: true,
            render: (text) => !isEmpty(text) ? brands.find(type => type.id === text).brand : BLANK_VALUE
        },
        {
            title: "Tea Grade",
            dataIndex: 'gardenTeaGrade',
            sorter: true,
            render: (text) => !isEmpty(text) ? teaGrades.find(type => type.id === text).teaGrade : BLANK_VALUE
        },
        {
            title: "Invoice Number",
            dataIndex: 'invoiceNumber',
            sorter: true
        },
        {
            title: "Rate",
            dataIndex: 'rate',
            sorter: true
        },
        {
            title: "Amount",
            dataIndex: 'amount',
            sorter: true
        },
        {
            title: "GST",
            dataIndex: 'gst',
            sorter: true
        },
        {
            title: "Transport",
            dataIndex: 'transport',
            sorter: true
        },
        {
            title: "Total Amount",
            dataIndex: 'totalAmount',
            sorter: true,
            render: (text, record) => calculateTotalAmount(record)
        },
        {
            title: "Purchase Date",
            dataIndex: 'purchaseDate',
            sorter: true,
            render: (text) => displayFormattedDate(text)
        },
        {
            title: "Quality Grade",
            dataIndex: 'qualityTeaGrade',
            sorter: true,
            render: (text) => !isEmpty(text) && grades.find(type => type.id === text).grade
        },
        {
            title: 'Action',
            key: 'action',
            fixed: "right",
            render: (text, record) => <Space>
                <Button size='small' onClick={() => editRecord(record)}>Edit</Button>
                <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => confirm(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button size='small'>Delete</Button>
                </Popconfirm>
            </Space>
        }
    ];
    const [responseData, getRequest] = useApi("/rawMaterial", null, API_METHODS.GET);
    const [deleteResponse, deleteRequest] = useApi(null, null, API_METHODS.DELETE);
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleSendToProcess, setVisibleSendToProcess] = useState(false);
    const [visibleProcessedEntries, setVisibleProcessedEntries] = useState(false);
    const [tableData, setTableData] = useState();
    const [selectedRecord, setSelectedRecord] = useState();

    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setTableData(data);
    }, [responseData]);

    useEffect(() => {
        if (deleteResponse.data) {
            message.success('Purchase deleted successfully.');
            getRequest();
        }
    }, [deleteResponse]);

    const handleAddRawMaterial = (isUpdated) => {
        const flag = visible ? false : true;
        setVisible(flag);
        isUpdated === true && getRequest();
    }

    const handleEditRawMaterial = (isUpdated) => {
        const flag = visibleEdit ? false : true;
        !flag && setSelectedRecord(null);
        setVisibleEdit(flag);
        isUpdated === true && getRequest();
    }

    const editRecord = (record) => {
        setVisibleEdit(true);
        setSelectedRecord(record);
    }

    const confirm = (record) => {
        deleteRecord(record)
    };

    const deleteRecord = (record) => {
        deleteRequest(null, API_METHODS.DELETE, `/rawMaterial/purchase/${record.id}`);
    }

    const handleSendToProcess = (isUpdated) => {
        const flag = visibleSendToProcess ? false : true;
        setVisibleSendToProcess(flag);
        isUpdated === true && getRequest();
    }

    const viewProcessedEntries = (record) => {
        setVisibleProcessedEntries(true);
        setSelectedRecord(record);
    }

    const handleProcessedEntries = (isUpdated) => {
        const flag = visibleProcessedEntries ? false : true;
        setVisibleProcessedEntries(flag);
        isUpdated === true && getRequest();
    }

    return <>
        <Row>
            <Col span={6}>
                <Typography.Title level={5}>Raw Material</Typography.Title>
            </Col>
            <Col span={18}>
                <Space style={{ marginBottom: 10, float: "right" }}>
                    <Button type='primary' onClick={handleAddRawMaterial}>Add Purchase</Button>
                    {/* <Button type='primary' onClick={handleSendToProcess}>Send to Process</Button> */}
                </Space>
            </Col>
        </Row>
        <TableComponent columns={columns} tableData={tableData} loading={responseData.isLoading} />
        {visible && <AddRawMaterial visible={visible} close={handleAddRawMaterial} />}
        {visibleEdit && <AddRawMaterial visible={visibleEdit} selectedRecord={selectedRecord} close={handleEditRawMaterial} />}
        {/* {visibleSendToProcess && <SendToProcess visible={visibleSendToProcess} tableData={tableData} close={handleSendToProcess} />} */}
        {/* {visibleProcessedEntries && <ProcessedEntries visible={visibleProcessedEntries} invoiceNumber={selectedRecord && selectedRecord.invoiceNumber} close={handleProcessedEntries} />} */}
    </>
}

export default RawMaterialTable;