import { Button, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import {
  getBrandFilters,
  getFilteredBrandRecords,
} from "../../../generalFunctions";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddPackagingInfo from "./AddPackagingInfo";

function PackagingInfoTable(props) {
  const columns = [
    {
      title: "Packaging Material",
      dataIndex: "packagingMaterial",
    },
    {
      title: "Number of Packets per Kg",
      dataIndex: "numberOfPacketsPerKg",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",

      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    "/config/packagingInfo",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedRecord, setSelectedRecord] = useState();

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  const handleAddPackagingInfo = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const handleEditPackagingInfo = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Typography.Title level={5}>Packaging Info</Typography.Title>
        </Col>
        <Col span={18}>
          <Space style={{ marginBottom: 10, float: "right" }}>
            <Button type="primary" onClick={handleAddPackagingInfo}>
              Add Packaging Info
            </Button>
          </Space>
        </Col>
      </Row>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
      />
      {visible && (
        <AddPackagingInfo visible={visible} close={handleAddPackagingInfo} />
      )}
      {visibleEdit && (
        <AddPackagingInfo
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditPackagingInfo}
        />
      )}
    </>
  );
}

export default PackagingInfoTable;
