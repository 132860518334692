import { Result, Space } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import {
  getClientConfig,
  getPackagingInvetoryBalance,
  getPackagingMaterialName,
  isEmpty,
} from "../../generalFunctions";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";

const PackagingMaterialInventoryReport = () => {
  const [responseData, getRequest] = useApi(
    "/packaging/purchaseByPackagingMaterialListId",
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();
  const {
    brandPackagingQuantity,
    brandPackagingTypeMapping,
    packagingInfo,
    packagingMaterialList,
  } = getClientConfig();
  const getFilters = () => {
    const filter = [];
    for (let i = 0; i < brandPackagingQuantity.length; i++) {
      filter.push({
        text: `${brandPackagingQuantity[i].brand} - ${brandPackagingQuantity[i].typeOfPackaging} - ${brandPackagingQuantity[i].packagingGrammage}`,
        value: brandPackagingQuantity[i].id,
      });
    }
    return filter;
  };

  const getFilteredRecords = (key, record) => {
    const brandPackagingTypeMappingRecords = brandPackagingTypeMapping.filter(
      (type) => type.brandPackagingQuantityId === key
    );

    for (let i = 0; i < brandPackagingTypeMappingRecords.length; i++) {
      if (
        brandPackagingTypeMappingRecords[i].packagingMaterialListId ===
        record.packagingMaterialListId
      ) {
        return true;
      }
    }
  };

  const getDownloadData = () => {
    //create data that has to be downloaded
    var downloadData = [];
    if (tableData) {
      for (let i = 0; i < packagingMaterialList.length; i++) {
        const brandPackagingTypeMappingRecords =
          brandPackagingTypeMapping.filter(
            (type) =>
              type.packagingMaterialListId === packagingMaterialList[i].id
          );

        if (brandPackagingTypeMappingRecords.length > 0) {
          const brandPackagingQuantityRecord = brandPackagingQuantity.find(
            (type) =>
              type.id ===
              brandPackagingTypeMappingRecords[0].brandPackagingQuantityId
          );

          var data = tableData.filter(
            (type) =>
              type.packagingMaterialListId === packagingMaterialList[i].id
          );

          if (data.length > 0) {
            var balance = 0;

            var packagingInfoRecord = packagingInfo.find(
              (type) =>
                type.packagingMaterialListId === packagingMaterialList[i].id
            );
            if (!isEmpty(packagingInfoRecord)) {
              let balanceInKgs =
                data[0].totalBalance / packagingInfoRecord.numberOfPacketsPerKg;

              balance = balanceInKgs.toFixed(2);
            } else {
              balance = data[0].totalBalance;
            }
            if (
              packagingMaterialList[i].packagingMaterial
                .toLowerCase()
                .includes("foil")
            ) {
              downloadData.push({
                brand: packagingMaterialList[i].packagingMaterial,
                packagingGrammage:
                  brandPackagingQuantityRecord.packagingGrammage,
                stock: balance,
                amount: data[0].cost,
              });
            } else if (packagingMaterialList[i].isOuterPackaging === 1) {
              let added = false;
              for (let j = 0; j < downloadData.length; j++) {
                if (
                  downloadData[j].brand ===
                    brandPackagingQuantityRecord.brand &&
                  downloadData[j].packagingGrammage ===
                    brandPackagingQuantityRecord.packagingGrammage &&
                  !("bagStock" in downloadData[j])
                ) {
                  downloadData[j].bagStock = balance;
                  downloadData[j].bagAmount = data[0].cost;
                  added = true;
                }
              }
              if (!added) {
                downloadData.push({
                  brand: brandPackagingQuantityRecord.brand,
                  packagingGrammage:
                    brandPackagingQuantityRecord.packagingGrammage,
                  bagStock: balance,
                  bagAmount: data[0].cost,
                });
              }
            } else {
              let added = false;
              for (let j = 0; j < downloadData.length; j++) {
                if (
                  downloadData[j].brand ===
                    brandPackagingQuantityRecord.brand &&
                  downloadData[j].packagingGrammage ===
                    brandPackagingQuantityRecord.packagingGrammage &&
                  !("stock" in downloadData[j])
                ) {
                  downloadData[j].stock = balance;
                  downloadData[j].amount = data[0].cost;
                  added = true;
                }
              }
              if (!added) {
                downloadData.push({
                  brand: brandPackagingQuantityRecord.brand,
                  packagingGrammage:
                    brandPackagingQuantityRecord.packagingGrammage,
                  stock: balance,
                  amount: data[0].cost,
                });
              }
            }
          }
        }
      }
    }
    downloadData.sort(function (a, b) {
      return a.brand.localeCompare(b.brand);
    });
    return downloadData;
  };

  const downloadHeaders = [
    {
      key: "brand",
      label: "BRAND",
    },
    {
      key: "packagingGrammage",
      label: "GM.",
    },
    {
      key: "stock",
      label: "CLOSING STOCK IN KG/NO.",
    },
    {
      key: "amount",
      label: "TOTAL RAW MTL. STOCK AMOUNT",
    },
    {
      key: "bagStock",
      label: "CLOSING STOCK",
    },
    {
      key: "bagAmount",
      label: "TOTAL AMOUNT BAG/BOX",
    },
  ];

  const columns = [
    {
      title: "Packaging",
      dataIndex: "packagingMaterialListId",
      sorter: true,
      render: (text) => getPackagingMaterialName(text),
      filters: getFilters(),
      onFilter: (key, record) => getFilteredRecords(key, record),
    },
    {
      title: "Stock",
      dataIndex: "totalBalance",
      render: (text, record) => getPackagingInvetoryBalance(text, record),
      sorter: true,
    },
  ];

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <>
        {packagingMaterialList !== BLANK_VALUE &&
        brandPackagingTypeMapping !== BLANK_VALUE ? (
          <TableComponent
            rowKey="packagingMaterialListId"
            columns={columns}
            tableData={tableData}
            loading={responseData.isLoading}
            showDownloadOption={true}
            downloadData={getDownloadData()}
            headers={downloadHeaders}
            filename={"PackagingInventoryReport"}
          />
        ) : (
          <Result
            status="error"
            title="Please add Packaging Material List, Brand Packaging Type Mapping"
          />
        )}
      </>
    </Space>
  );
};

export default PackagingMaterialInventoryReport;
