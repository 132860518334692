import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { displayFormattedDate } from '../../generalFunctions';
import useApi from '../../hooks/useApi';
import TableComponent from '../common/table/TableComponent';

const ProcessedEntries = (props) => {
    const { visible, close, packagingPurchaseId, packagingName } = props;
    const [responseData, getRequest] = useApi(`/packaging/processedEntries/${packagingPurchaseId}`, null, API_METHODS.GET);
    const [tableData, setTableData] = useState();
    const columns = [
        {
            title: "Quantity",
            dataIndex: 'quantity',
            sorter: true
        },
        {
            title: "Process Date",
            dataIndex: 'processDate',
            sorter: true,
            render: (text) => displayFormattedDate(text)
        }
    ];

    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setTableData(data);
    }, [responseData]);

    return (
        <Modal title={"Prossed Entries for " + packagingName} visible={visible} footer={null} width={1000} onCancel={close}>
            <TableComponent columns={columns} tableData={tableData} loading={responseData.isLoading} />
        </Modal>
    );
}

export default ProcessedEntries;