import { Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { getFilterValues, getClientConfig, isEmpty } from '../../generalFunctions';
import useApi from '../../hooks/useApi';
import TableComponent from '../common/table/TableComponent';

const RawMaterialInventoryReport = () => {
    const [responseData, getRequest] = useApi("/rawMaterial ", null, API_METHODS.GET);
    const [tableData, setTableData] = useState();
    const { teaGrades, purchaseTypes } = getClientConfig();

    const columns = [
        {
            title: "Garden Name / Auction Code",
            dataIndex: 'gardenAuctionName',
            sorter: true,
            filters: getFilterValues("gardenAuctionName", tableData),
            onFilter: (key, record) => record.gardenAuctionName == key
        },
        {
            title: "Quantity (bags)",
            dataIndex: 'numberOfBags',
            sorter: true
        },
        {
            title: "Quantity (kg)",
            dataIndex: 'quantityInKg',
            sorter: true
        },
        {
            title: "Tea Grade",
            dataIndex: 'gardenTeaGrade',
            sorter: true,
            render: (text, record) => !isEmpty(text) && teaGrades.find(type => type.id === text)?.teaGrade + (!isEmpty(record.invoiceNumber) ? " / " + record.invoiceNumber : "")
        },
    ];

    const downloadHeaders = [
        {
            key: "gardenAuctionName",
            label: "Garden Name / Auction Code"
        },
        {
            key: "numberOfBags",
            label: "Quantity (bags)"
        },
        {
            key: "quantityInKg",
            label: "Quantity (kg)"
        },
        {
            key: "teaGrade",
            label: "Tea Grade"
        }
    ];

    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setTableData(data);
    }, [responseData]);

    const checkPerchaseType = (typeId) => {
        const type = purchaseTypes.find(t => t.id === typeId).purchaseType;
        return type;
    }

    const getRecordByPurchaseType = (purchaseType) => {
        return tableData?.filter(p => checkPerchaseType(p.purchaseType).toLowerCase().includes(purchaseType));
    }

    const getAuctionPurchase = (type) => {
        const auctionPurchases = tableData?.filter(p => checkPerchaseType(p.purchaseType).toLowerCase().includes("auction"));
        if (type === 'brand') {
            return auctionPurchases.filter(p => !isEmpty(p.brandId));
        }
        if (type === 'grade') {
            return auctionPurchases.filter(p => !isEmpty(p.gardenTeaGrade));;
        }
    }

    const getDownloadData = () => {
        let downloadData = []
        if (tableData && tableData.length > 0) {
            let filterData = getRecordByPurchaseType("local")
            for (let i = 0; i < filterData.length; i++) {
                downloadData.push(filterData[i])
                downloadData[i].teaGrade = !isEmpty(filterData[i].gardenTeaGrade) && (teaGrades.find(type => type.id === filterData[i].gardenTeaGrade).teaGrade + (!isEmpty(filterData[i].invoiceNumber) ? " / " + filterData[i].invoiceNumber : ""));
            }

            filterData = getRecordByPurchaseType("private")
            for (let i = 0; i < filterData.length; i++) {
                downloadData.push(filterData[i])
                downloadData[downloadData.length - 1].teaGrade = !isEmpty(filterData[i].gardenTeaGrade) && (teaGrades.find(type => type.id === filterData[i].gardenTeaGrade).teaGrade + (!isEmpty(filterData[i].invoiceNumber) ? " / " + filterData[i].invoiceNumber : ""));
            }

            filterData = getAuctionPurchase("brand")
            for (let i = 0; i < filterData.length; i++) {
                downloadData.push(filterData[i])
                downloadData[downloadData.length - 1].teaGrade = !isEmpty(filterData[i].gardenTeaGrade) ? teaGrades.find(type => type.id === filterData[i].gardenTeaGrade).teaGrade : "";
            }

            filterData = getAuctionPurchase("grade")
            for (let i = 0; i < filterData.length; i++) {
                downloadData.push(filterData[i])
                downloadData[downloadData.length - 1].teaGrade = !isEmpty(filterData[i].gardenTeaGrade) ? teaGrades.find(type => type.id === filterData[i].gardenTeaGrade).teaGrade : "";
            }
        }

        return downloadData;
    }

    return <>
        {getRecordByPurchaseType("local")?.length > 0 && <Space direction='vertical' style={{ width: "100%" }}>
            <Typography.Title level={5}>Garden Purchase - Local Purchase</Typography.Title>
            <TableComponent columns={columns} tableData={getRecordByPurchaseType("local")} loading={responseData.isLoading} pagination={false} showDownloadOption={true} downloadData={getDownloadData()} headers={downloadHeaders} filename="Raw Material Inventory Report" />
        </Space>}
        {getRecordByPurchaseType("private")?.length > 0 && <Space direction='vertical' style={{ width: "100%", marginTop: 25 }}>
            <Typography.Title level={5}>Garden Purchase - Private Purchase</Typography.Title>
            <TableComponent columns={columns} tableData={getRecordByPurchaseType("private")} loading={responseData.isLoading} pagination={false} />
        </Space>}
        {getRecordByPurchaseType("auction")?.length > 0 && <Space direction='vertical' style={{ width: "100%", marginTop: 25 }}>
            <Typography.Title level={5}>Auction Purchase - By Brand</Typography.Title>
            <TableComponent columns={columns} tableData={getAuctionPurchase("brand")} loading={responseData.isLoading} pagination={false} />
        </Space>}
        {getRecordByPurchaseType("auction")?.length > 0 && <Space direction='vertical' style={{ width: "100%", marginTop: 25 }}>
            <Typography.Title level={5}>Auction Purchase - By Grade</Typography.Title>
            <TableComponent columns={columns} tableData={getAuctionPurchase("grade")} loading={responseData.isLoading} pagination={false} />
        </Space>}
    </>
}

export default RawMaterialInventoryReport;