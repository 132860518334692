import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Divider, Select, DatePicker, InputNumber, message, Result, Button, Alert } from 'antd';
import { formatDateToMoment, getClientConfig, isEmpty, replaceBlankValueToUndefined } from '../../generalFunctions';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import useApi from '../../hooks/useApi';
import moment from 'moment';

const AddPackagingMaterial = (props) => {
    const [form] = Form.useForm();
    const { visible, close, selectedRecord } = props;
    const [totalAmount, setTotalAmount] = useState();
    const [response, request] = useApi("/packaging/purchase", null, API_METHODS.POST);
    const [editResponse, editRequest] = useApi(`/packaging/purchase/${selectedRecord && selectedRecord.id}`, null, API_METHODS.PUT);
    const { packagingInfo, packagingMaterialList } = getClientConfig();

    useEffect(() => {
        if (response.data) {
            close(true);
            message.success('Purchase added successfully.');
        }
    }, [response]);

    useEffect(() => {
        if (editResponse.data) {
            close(true);
            message.success('Purchase updated successfully.');
        }
    }, [editResponse]);

    useEffect(() => {
        if (selectedRecord) {
            let initialValues = { ...selectedRecord };
            initialValues = replaceBlankValueToUndefined(initialValues);
            initialValues.purchaseDate = formatDateToMoment(initialValues.purchaseDate);
            form.setFieldsValue(initialValues);
            calculateTotalCharges(initialValues);
        }
    }, [selectedRecord]);

    const handleOk = () => {
        form.validateFields().then(values => {
            values.purchaseDate = !isEmpty(values.purchaseDate) ? values.purchaseDate : moment();
            if (selectedRecord) {
                editRequest(values);
            } else {
                const packagingInfoRecord = packagingInfo.find(record => record.packagingMaterialListId === values.packagingMaterialListId);
                if (packagingInfoRecord && !isEmpty(packagingInfoRecord.numberOfPacketsPerKg)) {
                    const totalPuchasedQuantity = values.purchasedQuantity * packagingInfoRecord.numberOfPacketsPerKg;
                    values.balance = totalPuchasedQuantity;
                    values.purchasedQuantityInUnits = totalPuchasedQuantity;
                }
                else {
                    values.balance = values.purchasedQuantity;
                    values.purchasedQuantityInUnits = values.purchasedQuantity;
                }
                request(values, API_METHODS.POST);
            }
        })
    };

    const handleCancel = () => {
        close();
    };

    const calculateTotalCharges = (selectedRecord) => {
        const { amount, gst, transport } = selectedRecord ? selectedRecord : form.getFieldsValue();
        const gstAmount = !isEmpty(gst) ? (amount * (gst / 100)) : 0;
        let totalAmount = (!isEmpty(amount) ? amount : 0) + (!isEmpty(gstAmount) ? gstAmount : 0) + (!isEmpty(transport) ? transport : 0);
        setTotalAmount(totalAmount);
    }

    const calculateAmount = (selectedRecord) => {
        const { purchasedQuantity, rate } = selectedRecord ? selectedRecord : form.getFieldsValue();
        let totalAmount = purchasedQuantity * rate;
        form.setFieldsValue({ "amount": totalAmount });
        calculateTotalCharges();
    }

    return (
        <>
            {packagingMaterialList !== BLANK_VALUE ? (
                <Modal title="Add Packaging Material" visible={visible} onOk={handleOk} onCancel={handleCancel} width={1000} okText="Save">
                    <Alert style={{ marginBottom: 15 }} message="Please make sure for Roll based packaging, you have added Packagin Info (Number of Packets per Kg)" type="info" showIcon />
                    <Form layout="vertical" form={form} initialValues={{ purchaseDate: moment() }}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item name="packagingMaterialListId" label="Packaging" rules={[{ required: true, message: "Packaging Type is required." }]}>
                                    <Select placeholder="Select Option" allowClear showSearch filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                        {packagingMaterialList.map(option =>
                                            <Select.Option key={option.id} value={option.id}>{option.packagingMaterial}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="purchasedQuantity" label="Quantity" rules={[{ required: true, message: "Quantity is required." }]}>
                                    <InputNumber placeholder="Quantity" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="purchaseDate" label="Inward Date">
                                    <DatePicker placeholder="Purchase Date" rules={[{ required: true, message: "Purchase Date is required." }]} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation="left">Rate Information</Divider>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item name="rate" label="Rate" rules={[{ required: true, message: "Rate is required." }]}>
                                    <InputNumber placeholder="Rate" onChange={() => calculateAmount()} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="amount" label="Amount" rules={[{ required: true, message: "Amount is required." }]}>
                                    <InputNumber placeholder="Amount" onChange={() => calculateTotalCharges()} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="gst" label="GST (in %)" rules={[{ required: true, message: "GST is required." }]}>
                                    <InputNumber placeholder="GST" onChange={() => calculateTotalCharges()} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item name="transport" label="Transport Charges">
                                    <InputNumber placeholder="Transport" onChange={() => calculateTotalCharges()} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Total Amount">
                                    <h4>{totalAmount}</h4>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>) : (
                <Modal
                    visible={visible}
                    width={1000}
                    onCancel={handleCancel}
                    footer={<Button onClick={handleCancel}>Cancel</Button>}
                >
                    <Result status="error" title="Please add Packging Material List" />
                </Modal>
            )}
        </>
    );
};

export default AddPackagingMaterial;