import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  message,
  InputNumber,
  Select,
  Result,
  Button,
  Alert,
} from "antd";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import {
  getBrandPackagingName,
  getClientConfig,
} from "../../../generalFunctions";

function AddBagConversion(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi(
    "/config/bagConversion",
    null,
    API_METHODS.POST
  );
  const [editResponse, editRequest] = useApi(
    `/config/bagConversion/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );
  const { brandPackagingQuantity } = getClientConfig();

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Bag Conversion added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Bag Conversion updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        request(values, API_METHODS.POST);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <>
      {brandPackagingQuantity !== BLANK_VALUE ? (
        <Modal
          title="Add Bag Conversion"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          okText="Save"
        >
          <Alert
            style={{ marginBottom: 15 }}
            message="Please do not add this for sachets based Packaging"
            type="info"
            showIcon
          />
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="brandPackagingQuantityId"
                  label="Packaging"
                  rules={[
                    { required: true, message: "Packaging Type is required." },
                  ]}
                >
                  <Select
                    placeholder="Select Option"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {brandPackagingQuantity.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {getBrandPackagingName(option)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="numberOfKgsPerBag"
                  label="Number Of Kgs Per Bag"
                  rules={[
                    {
                      required: true,
                      message: "Number Of Kgs Per Bag is required.",
                    },
                  ]}
                >
                  <InputNumber placeholder="Number Of Kgs Per Bag" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1000}
          onCancel={handleCancel}
          footer={<Button onClick={handleCancel}>Cancel</Button>}
        >
          <Result status="error" title="Please add Brand Packaging Quantity" />
        </Modal>
      )}
    </>
  );
}

export default AddBagConversion;
