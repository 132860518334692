import { Button, Card, Col, Form, Input, message, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_METHODS, BLANK_VALUE } from "../constants";
import { isEmpty } from "../generalFunctions";
import useApi from "../hooks/useApi";
import logo from '../assests/images/logo1.png';
import ServerDownResult from "./common/ServerDownResult";

function Login() {

  const [response, request] = useApi("/login", null, API_METHODS.POST);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const LoginClicked = () => {
    form.validateFields().then((values) => {
      request(values, API_METHODS.POST);
    });
  };

  useEffect(() => {
    if (!isEmpty(response) && !isEmpty(response.data) && !response.isLoading) {
      if (response.data.authToken) {
        localStorage.setItem("authToken", response.data.authToken);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        navigate("/inventory/dashboard");
      }
    }
  }, [response]);

  return (
    <>
      {!isEmpty(response) ? <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Col xs={20} sm={16} md={12} lg={10} xl={8}>
          <div style={{ textAlign: "center", paddingBottom: 15 }}>
            <img style={{ height: 65, width: "40%", backgroundColor: "black" }} src={logo} />
          </div>
          <Typography.Title level={3} style={{ marginBottom: 15, textAlign: "center" }}>Tea Inventory Management</Typography.Title>
          <Card title={"Login"}>
            <Form layout="vertical" onFinish={LoginClicked} form={form}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                  { max: 20 },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  { max: 15 },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
        : <ServerDownResult />}
    </>

  );
}

export default Login;
