import { Result, Space } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import {
  getBrandFilters,
  getClientConfig,
  getPackagingByBrandPackagingQuantityId,
  getStockInNumberOfBags,
  isEmpty,
} from "../../generalFunctions";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
function TeaInventoryReport() {
  const [responseData, getRequest] = useApi(
    "/teaInventory/inventory",
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();
  const {
    brandPackagingQuantity,
    pricing,
    bagConversion,
    brands,
    sachetSetting,
  } = getClientConfig();

  const getFilteredRecords = (key, record) => {
    const brandPackagingQuantityRecords = brandPackagingQuantity.filter(
      (type) => type.brandId === key
    );
    let keys = [];
    for (let i = 0; i < brandPackagingQuantityRecords.length; i++) {
      keys.push(brandPackagingQuantityRecords[i].id);
    }
    if (keys.includes(record.brandPackagingQuantityId)) {
      return true;
    }
  };
  const showKgPerBag = (key) => {
    const bagConversionRecord = bagConversion.find(
      (type) => type.brandPackagingQuantityId === key
    );
    const sachetSettingRecord = sachetSetting.find(
      (type) => type.brandPackagingQuantityId === key
    );
    if (!isEmpty(bagConversionRecord)) {
      return bagConversionRecord.numberOfKgsPerBag;
    } else if (!isEmpty(sachetSettingRecord)) {
      var pouchesInPuda = sachetSettingRecord.pouchesInPuda;
      var extraPouchesInPuda = !isEmpty(sachetSettingRecord.extraPouchesInPuda)
        ? sachetSettingRecord.extraPouchesInPuda
        : 0;
      var pudeInBag = sachetSettingRecord.pudeInBag;
      var extraPouchesInBag = !isEmpty(sachetSettingRecord.extraPouchesInBag)
        ? sachetSettingRecord.extraPouchesInBag
        : 0;

      var packetsInOneBag =
        (pouchesInPuda + extraPouchesInPuda) * pudeInBag + extraPouchesInBag;
      let KgperBag =
        (packetsInOneBag * sachetSettingRecord.packagingGrammage) / 1000;
      return KgperBag.toFixed(2);
    } else {
      return ""; //default (never required)
    }
  };

  const getDownloadData = () => {
    //create data that has to be downloaded
    var downloadData = [];
    if (tableData) {
      for (let i = 0; i < tableData.length; i++) {
        downloadData.push(tableData[i]);
        downloadData[i].packaging = getPackagingByBrandPackagingQuantityId(
          tableData[i].brandPackagingQuantityId
        );
        downloadData[i].packaging = getPackagingByBrandPackagingQuantityId(
          tableData[i].brandPackagingQuantityId
        );
        downloadData[i].stockInBags = showStockInBags(
          tableData[i].stock,
          tableData[i]
        );
        downloadData[i].stockInKgs = showStockInKgs(
          tableData[i].stock,
          tableData[i]
        );
        downloadData[i].kgPerBag = showKgPerBag(
          tableData[i].brandPackagingQuantityId
        );
        downloadData[i].rate = showRate(tableData[i].stock, tableData[i]);
        downloadData[i].amount = showAmount(tableData[i].stock, tableData[i]);
      }
    }
    return downloadData;
  };

  const downloadHeaders = [
    {
      key: "packaging",
      label: "Packaging",
    },
    {
      key: "stockInBags",
      label: "Stock",
    },
    {
      key: "stockInKgs",
      label: "Stock(in Kgs)",
    },
    {
      key: "kgPerBag",
      label: "Kg per Bag",
    },
    {
      key: "rate",
      label: "Rate",
    },
    {
      key: "amount",
      label: "Amount",
    },
  ];

  const columns = [
    {
      title: "Packaging",
      dataIndex: "brandPackagingQuantityId",
      render: (text) => getPackagingByBrandPackagingQuantityId(text),
      filters: getBrandFilters(),
      onFilter: (key, record) => getFilteredRecords(key, record),
    },
    {
      title: "Stock",
      dataIndex: "stock",
      render: (text, record) => showStockInBags(text, record),
      sorter: true,
    },
    {
      title: "Stock(in Kgs)",
      dataIndex: "stock",
      render: (text, record) => showStockInKgs(text, record),
      sorter: true,
    },
    {
      title: "Kg per Bag",
      dataIndex: "brandPackagingQuantityId",
      render: (text) => showKgPerBag(text),
      sorter: true,
    },
    {
      title: "Rate",
      sorter: true,
      dataIndex: "stock",
      render: (text, record) => showRate(text, record),
    },
    {
      title: "Amount",
      dataIndex: "stock",
      render: (text, record) => showAmount(text, record),
    },
  ];

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  const showStockInBags = (text, record) => {
    let brandPackagingQuantityRecord = brandPackagingQuantity.find(
      (type) => type.id === record.brandPackagingQuantityId
    );

    if (isEmpty(brandPackagingQuantityRecord)) {
      return "-";
    }

    return getStockInNumberOfBags(
      text,
      brandPackagingQuantityRecord.packagingGrammage,
      record.brandPackagingQuantityId
    );
  };
  const showStockInKgs = (text, record) => {
    let brandPackagingQuantityRecord = brandPackagingQuantity.find(
      (type) => type.id === record.brandPackagingQuantityId
    );

    if (isEmpty(brandPackagingQuantityRecord)) {
      return "-";
    }

    return (
      (text * brandPackagingQuantityRecord.packagingGrammage) /
      1000
    ).toFixed(2);
  };

  const showRate = (text, record) => {
    let pricingRecord = pricing.find(
      (type) =>
        type.brandPackagingQuantityId === record.brandPackagingQuantityId
    );

    if (isEmpty(pricingRecord)) {
      return "-";
    }
    return pricingRecord.price.toFixed(2);
  };

  const showAmount = (text, record) => {
    let pricingRecord = pricing.find(
      (type) =>
        type.brandPackagingQuantityId === record.brandPackagingQuantityId
    );

    if (isEmpty(pricingRecord)) {
      return "-";
    }

    let rate = pricingRecord.price;
    return (text * rate).toFixed(2);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <>
        {brandPackagingQuantity !== BLANK_VALUE &&
        pricing !== BLANK_VALUE &&
        (bagConversion !== BLANK_VALUE || sachetSetting !== BLANK_VALUE) &&
        brands !== BLANK_VALUE ? (
          <TableComponent
            columns={columns}
            tableData={tableData}
            loading={responseData.isLoading}
            showDownloadOption={true}
            downloadData={getDownloadData()}
            headers={downloadHeaders}
            filename={"TeaInventoryReport"}
          />
        ) : (
          <Result
            status="error"
            title="Please add Brand, Brand Packaging Quantity, Pricing and Bag Conversion"
          />
        )}
      </>
    </Space>
  );
}

export default TeaInventoryReport;
