import { Col, Row, Select, Typography } from 'antd';
import React, { useState } from 'react';
import PackagingMaterialInventoryReport from './PackagingMaterialInventoryReport';
import PackagingMaterialPurchaseReport from './PackagingMaterialPurchaseReport';
import RawMaterialInventoryReport from './RawMaterialInventoryReport';
import RawMaterialPurchaseReport from './RawMaterialPurchaseReport';
import TeaInventoryReport from './TeaInventoryReport';

const ReportTable = (props) => {
    const [reportName, setReportName] = useState({ name: "Report" });

    const loadReport = (reportName) => {
        switch (reportName) {
            // case "rawMaterialPurchase":
            //     setReportName({
            //         key: "rawMaterialPurchase",
            //         name: "Raw Material Purchase"
            //     });
            //     break;
            case "rawMaterialInventory":
                setReportName({
                    key: "rawMaterialInventory",
                    name: "Raw Material Inventory"
                });
                break;
            case "packagingMaterialPurchase":
                setReportName({
                    key: "packagingMaterialPurchase",
                    name: "Packaging Material Purchase"
                });
                break;
            case "packagingInventory":
                setReportName({
                    key: "packagingInventory",
                    name: "Packaging Inventory"
                });
                break;
            case "teaInventory":
                setReportName({
                    key: "teaInventory",
                    name: "Tea Inventory"
                });
                break;
            default:
                return [];
        }
    }

    return <>
        <Row style={{ paddingBottom: 40 }}>
            <Col span={4}>
                <Typography.Title level={5}>{reportName.name}</Typography.Title>
            </Col>
            <Col span={6} offset={4}>
                <Select
                    showSearch
                    placeholder="Select option"
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    onChange={loadReport}
                >
                    {/* <Select.Option value="rawMaterialPurchase">Raw Material Purchase</Select.Option> */}
                    <Select.Option value="rawMaterialInventory">Raw Material Inventory</Select.Option>
                    <Select.Option value="packagingMaterialPurchase">Packaging Material Purchase</Select.Option>
                    <Select.Option value="packagingInventory">Packaging Inventory</Select.Option>
                    <Select.Option value="teaInventory">Tea Inventory - Ready for Dispatch</Select.Option>
                </Select>
            </Col>
        </Row>
        {/* {
            reportName.key === "rawMaterialPurchase" && <RawMaterialPurchaseReport />
        } */}
        {
            reportName.key === "rawMaterialInventory" && <RawMaterialInventoryReport />
        }
        {
            reportName.key === "packagingMaterialPurchase" && <PackagingMaterialPurchaseReport />
        }
        {
            reportName.key === "packagingInventory" && <PackagingMaterialInventoryReport />
        }
        {
            reportName.key === "teaInventory" && <TeaInventoryReport />
        }
    </>
}

export default ReportTable;