import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Col,
  Row,
  message,
  Select,
  InputNumber,
  Radio,
  Space,
  Button,
  Result,
} from "antd";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import { getClientConfig } from "../../generalFunctions";

function AddInwardBags(props) {
  const [form] = Form.useForm();
  const { visible, close } = props;
  const [response, request] = useApi(
    "/teaInventory/inwardBags",
    null,
    API_METHODS.POST
  );
  const { brands, inwardBagConversion } = getClientConfig();
  const [inwardBagConvesionForBrand, setInwardBagConvesionForBrand] = useState(
    []
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Inward Bags added successfully.");
    }
  }, [response]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      request(values, API_METHODS.POST);
    });
  };

  const handleCancel = () => {
    close();
  };
  const getInwardBagConversion = (value) => {
    form.resetFields();
    form.setFieldsValue({ brandId: value });

    if (inwardBagConversion === BLANK_VALUE) {
      message.error("Please add inward bags conversion");
      return form.resetFields();
    }

    let inwardBagConversionForSelectedBrand = inwardBagConversion.filter(
      (type) => type.brandId === value
    );
    let radio = [];
    if (inwardBagConversionForSelectedBrand.length === 0) {
      message.error("Please add inward bag conversion for selected brand.");
      setInwardBagConvesionForBrand([]);
      return form.resetFields();
    }
    for (let i = 0; i < inwardBagConversionForSelectedBrand.length; i++) {
      radio.push(
        <Radio value={inwardBagConversionForSelectedBrand[i].numberOfKgsPerBag}>
          {inwardBagConversionForSelectedBrand[i].numberOfKgsPerBag}
        </Radio>
      );
    }
    setInwardBagConvesionForBrand(
      <>
        <Form.Item
          name="numberOfKgsPerBag"
          label="Number Of Kgs Per Bag"
          rules={[
            { required: true, message: "Number Of Kgs Per Bag is required." },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">{radio}</Space>
          </Radio.Group>
        </Form.Item>
      </>
    );
  };

  return (
    <>
      {brands !== BLANK_VALUE ? (
        <Modal
          title="Add Inward Bags"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          okText="Save"
        >
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="brandId"
                  label="Brand"
                  rules={[{ required: true, message: "Brand is required." }]}
                >
                  <Select
                    placeholder="Select Option"
                    onChange={getInwardBagConversion}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {brands.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.brand}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="inwardBags"
                  label="Inward Bags"
                  rules={[
                    {
                      required: true,
                      message: "Inward Bags is required.",
                    },
                  ]}
                >
                  <InputNumber placeholder="Inward Bags" />
                </Form.Item>
              </Col>
            </Row>
            {inwardBagConvesionForBrand}
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1000}
          onCancel={handleCancel}
          footer={<Button onClick={handleCancel}>Cancel</Button>}
        >
          <Result status="error" title="Please add Brand" />
        </Modal>
      )}
    </>
  );
}

export default AddInwardBags;
