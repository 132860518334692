import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';

const BarGraph = (props) => {
    const { data } = props;
    const config = {
        data,
        xField: 'value',
        yField: 'type',
        seriesField: 'type',
        legend: {
            position: 'top-left',
        },
    };
    return <Bar {...config} />;
};

export default BarGraph;
