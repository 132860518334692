import { Button, Col, DatePicker, Form, Row, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { displayFormattedDate, getClientConfig, getPackagingMaterialName, getPackagingUnit, isEmpty } from '../../generalFunctions';
import useApi from '../../hooks/useApi';
import TableComponent from '../common/table/TableComponent';

const { RangePicker } = DatePicker;

const PackagingMaterialPurchaseReport = () => {
    const { brandPackagingQuantity, brandPackagingTypeMapping } = getClientConfig();
    const [form] = Form.useForm();

    const getFilters = () => {
        const filter = [];
        for (let i = 0; i < brandPackagingQuantity.length; i++) {
            filter.push({ text: `${brandPackagingQuantity[i].brand} - ${brandPackagingQuantity[i].typeOfPackaging} - ${brandPackagingQuantity[i].packagingGrammage}`, value: brandPackagingQuantity[i].id });
        }
        return filter;
    };

    const getFilteredRecords = (key, record) => {
        const brandPackagingTypeMappingRecords = brandPackagingTypeMapping.filter(type => type.brandPackagingQuantityId === key);

        for (let i = 0; i < brandPackagingTypeMappingRecords.length; i++) {
            if (brandPackagingTypeMappingRecords[i].packagingMaterialListId === record.packagingMaterialListId) {
                return true
            }
        }
    };

    const columns = [
        {
            title: "Packaging",
            dataIndex: 'packagingMaterialListId',
            render: (text) => getPackagingMaterialName(text),
            filters: getFilters(),
            onFilter: (key, record) => getFilteredRecords(key, record),
        },
        {
            title: "Quantity",
            dataIndex: 'purchasedQuantity',
            sorter: true,
            render: (text, record) => <>{text + " " + getPackagingUnit(record.packagingMaterialListId)}</>
        },
        {
            title: "Inward Date",
            dataIndex: 'purchaseDate',
            sorter: true,
            render: (text) => displayFormattedDate(text)
        },
        {
            title: "Rate",
            dataIndex: 'rate',
            sorter: true
        },
        {
            title: "Amount",
            dataIndex: 'amount',
            sorter: true
        },
        {
            title: "GST",
            dataIndex: 'gst',
            sorter: true
        },
        {
            title: "Transport",
            dataIndex: 'transport',
            sorter: true
        },
        {
            title: "Total Amount",
            dataIndex: 'totalAmount',
            sorter: true,
            render: (text, record) => calculateTotalAmount(record)
        }
    ];
    const [responseData, postRequest] = useApi("/report/packagingMaterialPurchase", null, API_METHODS.POST);
    const [tableData, setTableData] = useState();

    useEffect(() => {
        getReport();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setTableData(data);
    }, [responseData]);

    const getReport = () => {
        const dateRange = {
            fromDate: moment().startOf('M'),
            toDate: moment()
        };
        postRequest(dateRange);
    }

    const searchReport = () => {
        form.validateFields().then(values => {
            const dateRange = {
                fromDate: values.dateRange[0],
                toDate: values.dateRange[1]
            };
            postRequest(dateRange);
        }
        ).catch();
    }

    const refersh = () => {
        form.resetFields();
        getReport();
    }

    const calculateTotalAmount = (selectedRecord) => {
        const { amount, gst, transport } = selectedRecord;
        const gstAmount = !isEmpty(gst) ? (amount * (gst / 100)) : 0;
        let totalAmount = (amount ? amount : 0) + (gstAmount ? gstAmount : 0) + (transport ? transport : 0);
        return totalAmount;
    }

    return <>
        <Space direction='vertical' style={{ width: "100%" }}>
            <Form form={form} layout="vertical" initialValues={{ dateRange: [moment().startOf('M'), moment()] }}>
                <Row gutter={32}>
                    <Col span={10}>
                        <Form.Item name="dateRange" label="Date Range" rules={[{
                            required: true,
                            message: "Start and End Date is required."
                        }]}>
                            <RangePicker
                                allowEmpty={['false', 'false']} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label=" ">
                            <Space>
                                <Button type='primary' size='middle' onClick={searchReport}>Search</Button>
                                <Button type='ghost' size='middle' onClick={refersh}>Reset Search</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <TableComponent columns={columns} tableData={tableData} loading={responseData.isLoading} />
        </Space>
    </>
}

export default PackagingMaterialPurchaseReport;