import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Select,
  DatePicker,
  InputNumber,
  message,
  Button,
  Space,
  Result,
} from "antd";
import {
  getBrandPackagingName,
  getClientConfig,
  getStockInNumberOfBags,
  isEmpty,
} from "../../generalFunctions";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import moment from "moment";
import useApi from "../../hooks/useApi";

function AddTeaDispatch(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [
    selectedBrandPackagingQuantityRecord,
    setSelectedBrandPackagingQuantity,
  ] = useState();
  // const [outwardPackets, setOutwardPackets] = useState();
  const [stockInNumberOfBags, setStockInNumberOfBags] = useState();
  const [response, request] = useApi(
    "/teaInventory/teaDispatch",
    null,
    API_METHODS.POST
  );
  const [editResponse, editRequest] = useApi(
    `/teaInventory/teaDispatch/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );
  const { brandPackagingQuantity, bagConversion, sachetSetting } =
    getClientConfig();

  const [inventory, getTeaInventory] = useApi(
    "/teaInventory",
    null,
    API_METHODS.GET
  );

  useEffect(() => {
    getTeaInventory();
  }, []);

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Tea Dispatch added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Tea Dispatch updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  // useEffect(() => {
  //   if (selectedBrandPackagingQuantityRecord) {
  //     calculateOutwardPackets(form.getFieldValue("outwardBags"));
  //   }
  // }, [selectedBrandPackagingQuantityRecord]);

  const getDispatchQuantity = (values) => {
    var packagingGrammage = brandPackagingQuantity.find(
      (type) => type.id === values.brandPackagingQuantityId
    ).packagingGrammage;
    if (!isEmpty(bagConversion)) {
      var bagConversionRecord = bagConversion.find(
        (type) =>
          type.brandPackagingQuantityId === values.brandPackagingQuantityId
      );
    }
    if (!isEmpty(sachetSetting)) {
      var sachetSettingRecords = sachetSetting.filter(
        (type) =>
          type.brandPackagingQuantityId === values.brandPackagingQuantityId
      );
    }
    if (!isEmpty(bagConversionRecord)) {
      var numberOfKgsPerBag = bagConversionRecord.numberOfKgsPerBag;
      return Math.floor(
        (numberOfKgsPerBag * values.outwardBags * 1000) / packagingGrammage
      );
    } else if (sachetSettingRecords.length > 0) {
      var sachetInOnePuda = 0;
      var extraSachetInOnePuda = 0;
      var pudeInOneBag = 0;
      var extraSachetInOneBag = 0;
      for (let i = 0; i < sachetSettingRecords.length; i++) {
        if (sachetSettingRecords[i].isPudaPackaging === 1) {
          sachetInOnePuda = sachetSettingRecords[i].sachet;
          extraSachetInOnePuda = !isEmpty(sachetSettingRecords[i].extraSachet)
            ? sachetSettingRecords[i].extraSachet
            : 0;
        }
        if (sachetSettingRecords[i].isOuterPackaging === 1) {
          pudeInOneBag = sachetSettingRecords[i].sachet;
          extraSachetInOneBag = !isEmpty(sachetSettingRecords[i].extraSachet)
            ? sachetSettingRecords[i].extraSachet
            : 0;
        }
      }
      var packetsInOneBag =
        (sachetInOnePuda + extraSachetInOnePuda) * pudeInOneBag +
        extraSachetInOneBag;
      return Math.floor(values.outwardBags * packetsInOneBag);
    }
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        values.outwardQuantity = getDispatchQuantity(values); // quantity
        editRequest(values);
      } else {
        values.outwardQuantity = getDispatchQuantity(values); // quantity
        request(values, API_METHODS.POST);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  const PackagingSelected = (brandPackagingQuantityId) => {
    if (isEmpty(bagConversion) && isEmpty(sachetSetting)) {
      message.error("Please add bag conversion or Sachet Setting");
      setStockInNumberOfBags();
      setSelectedBrandPackagingQuantity();
      return form.resetFields();
    }

    const record = inventory.data.find(
      (record) => record.brandPackagingQuantityId === brandPackagingQuantityId
    );

    if (record === undefined) {
      message.error("No invetory record to dispatch for selected packaging.");
      setStockInNumberOfBags();
      setSelectedBrandPackagingQuantity();
      return form.resetFields();
    }
    if (record.stock === 0) {
      message.error("Stock is zero for selected packaging.");
      return form.resetFields();
    }
    setSelectedBrandPackagingQuantity(record);
    setStockInNumberOfBags(
      getStockInNumberOfBags(
        record.stock,
        record.packagingGrammage,
        record.brandPackagingQuantityId
      )
    );
  };

  const customValidation = (value) => {
    if (form.getFieldValue("brandPackagingQuantityId") === undefined) {
      message.error("Please select packaging first");
      return form.setFieldsValue({ outwardBags: 0 });
    }
    if (value > stockInNumberOfBags) {
      message.error("Quantity Should be less than available stock");
      // setOutwardPackets(null);
      return form.setFieldsValue({ outwardBags: 0 });
    }
    // if (!isEmpty(value)) {
    //   const bagConversionRecord = bagConversion.find(
    //     (item) =>
    //       item.brandPackagingQuantityId ===
    //       selectedBrandPackagingQuantityRecord.brandPackagingQuantityId
    //   );
    //   const outwardPackets =
    //     (1000 * value * bagConversionRecord.numberOfKgsPerBag) /
    //     bagConversionRecord.packagingGrammage;
    //   setOutwardPackets(outwardPackets);
    // } else {
    //   setOutwardPackets(null);
    // }
  };

  return (
    <>
      {brandPackagingQuantity !== BLANK_VALUE ? (
        <Modal
          title="Tea Dispatch"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          okText="Dispatch"
          // okButtonProps={{
          //   disabled:
          //     outwardPackets &&
          //     selectedBrandPackagingQuantityRecord.stock < outwardPackets,
          // }}
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={{ dispatchDate: moment() }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="brandPackagingQuantityId"
                  label="Packaging"
                  rules={[
                    { required: true, message: "Packaging Type is required." },
                  ]}
                >
                  <Select
                    placeholder="Select Option"
                    onChange={PackagingSelected}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {brandPackagingQuantity.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {getBrandPackagingName(option)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="outwardBags"
                  label="Quantity(Number of Bags)"
                  rules={[
                    {
                      required: true,
                      message: "Quantity is required.",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Quantity"
                    onChange={customValidation}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="dispatchDate"
                  label="Dispatch Date"
                  rules={[
                    { required: true, message: "Dispatch Date is required." },
                  ]}
                >
                  <DatePicker placeholder="Dispatch Date" />
                </Form.Item>
              </Col>
            </Row>

            {selectedBrandPackagingQuantityRecord && (
              <Row>
                <Col span={4}>
                  <Form.Item label="Available Stock">
                    {stockInNumberOfBags}
                  </Form.Item>
                </Col>
                {/* <Col span={4}>
                  <Form.Item label="Number of Kg(s) / Bag">
                    <h4>
                      {
                        bagConversion.find(
                          (item) =>
                            item.brandPackagingQuantityId ===
                            selectedBrandPackagingQuantityRecord.brandPackagingQuantityId
                        ).numberOfKgsPerBag
                      }
                    </h4>
                  </Form.Item>
                </Col> */}
                {/* {outwardPackets && (
                  <Col span={4}>
                    <Form.Item label="Outward Packets">
                      <h4>{outwardPackets}</h4>
                    </Form.Item>
                  </Col>
                )} */}
              </Row>
            )}
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1000}
          onCancel={handleCancel}
          footer={<Button onClick={handleCancel}>Cancel</Button>}
        >
          <Result status="error" title="Please add Brand Packaging Quantity" />
        </Modal>
      )}
    </>
  );
}

export default AddTeaDispatch;
