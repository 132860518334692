import { Button, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import {
  getBrandFilters,
  getFilteredBrandRecords,
} from "../../generalFunctions";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import AddInwardBags from "./AddInwardBags";

function InwardBagsTable(props) {
  const columns = [
    {
      title: "Brand Name",
      dataIndex: "brand",
      filters: getBrandFilters(),
      onFilter: (key, record) => getFilteredBrandRecords(key, record),
    },
    {
      title: "Stock (bags)",
      dataIndex: "stock",
      sorter: true,
    },
    {
      title: "Number Of Kgs Per Bag",
      dataIndex: "numberOfKgsPerBag",
      sorter: true,
    },
  ];
  const [responseData, getRequest] = useApi(
    "/teaInventory/inwardBags",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedRecord, setSelectedRecord] = useState();

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  const handleAddInwardBags = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    isUpdated === true && getRequest();
  };

  const handleEditInwardBags = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    isUpdated === true && getRequest();
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Typography.Title level={5}>Inward Bags</Typography.Title>
        </Col>
        <Col span={18}>
          <Space style={{ marginBottom: 10, float: "right" }}>
            <Button type="primary" onClick={handleAddInwardBags}>
              Add Inward Bags
            </Button>
          </Space>
        </Col>
      </Row>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
      />
      {visible && (
        <AddInwardBags visible={visible} close={handleAddInwardBags} />
      )}
      {visibleEdit && (
        <AddInwardBags
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditInwardBags}
        />
      )}
    </>
  );
}

export default InwardBagsTable;
