import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  message,
  InputNumber,
  Select,
  Result,
  Button,
} from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";
import {
  getBrandPackagingName,
  getClientConfig,
} from "../../../generalFunctions";
import { BLANK_VALUE } from "../../../constants";
function AddPricing(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi("/config/pricing", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/pricing/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );
  const { brandPackagingQuantity } = getClientConfig();

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Rate added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Rate updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        request(values, API_METHODS.POST);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <>
      {brandPackagingQuantity !== BLANK_VALUE ? (
        <Modal
          title="Add Rate"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          okText="Save"
        >
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="brandPackagingQuantityId"
                  label="Packaging"
                  rules={[
                    { required: true, message: "Packaging Type is required." },
                  ]}
                >
                  <Select
                    placeholder="Select Option"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {brandPackagingQuantity.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {getBrandPackagingName(option)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="price"
                  label="Rate"
                  rules={[{ required: true, message: "Rate is required." }]}
                >
                  <InputNumber placeholder="Rate" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1000}
          onCancel={handleCancel}
          footer={<Button onClick={handleCancel}>Cancel</Button>}
        >
          <Result status="error" title="Please add Brand Packaging Quantity" />
        </Modal>
      )}
    </>
  );
}

export default AddPricing;
