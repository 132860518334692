import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Divider,
  Select,
  DatePicker,
  InputNumber,
  message,
  Button,
  Space,
  Result,
  Radio,
  Typography,
} from "antd";
import {
  getBrandPackagingName,
  getClientConfig,
  isEmpty,
} from "../../generalFunctions";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import moment from "moment";

const { confirm } = Modal;
const { Text } = Typography;

function AddTeaProcessing(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;

  const [damagedPackaging, setDamagedPackaging] = useState([]);
  const [processedBag, setProcessedBag] = useState([]);
  const [balancePackaging, setBalancePackaging] = useState([]);

  const [response, request] = useApi(
    "/teaInventory/teaProcessing",
    null,
    API_METHODS.POST
  );

  const [editResponse, editRequest] = useApi(
    `/teaInventory/teaProcessing/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );
  const [inwardBagsData, getInwardBagsData] = useApi(
    "/teaInventory/inwardBags",
    null,
    API_METHODS.GET
  );
  const [inwardBagConvesionForBrand, setInwardBagConvesionForBrand] = useState(
    []
  );

  const {
    brandPackagingQuantity,
    brandPackagingTypeMapping,
    bagConversion,
    sachetSetting,
    packagingMaterialList
  } = getClientConfig();

  const [packagingData, getPackagingRequest] = useApi(
    "/packaging/purchaseByPackagingMaterialListId",
    null,
    API_METHODS.GET
  ); //packaging

  useEffect(() => {
    getInwardBagsData();
  }, []);

  useEffect(() => {
    let formItems = [];
    if (!packagingData.isLoading) {
      if (form.getFieldValue("brandPackagingQuantityId")) {
        if (isEmpty(packagingData.data)) {
          message.error("Please purchase packaging material");
          setDamagedPackaging([]);
          setBalancePackaging([]);
          setInwardBagConvesionForBrand([]);
          return form.resetFields();
        }

        let brandPackagingTypeMappingRecords = brandPackagingTypeMapping.filter(
          (type) =>
            type.brandPackagingQuantityId ===
            form.getFieldValue("brandPackagingQuantityId")
        );

        if (brandPackagingTypeMappingRecords.length === 0) {
          message.error(
            "Please add Brand Packaging Type Relation for selected Packaging"
          );
          setDamagedPackaging([]);
          setBalancePackaging([]);
          setInwardBagConvesionForBrand([]);
          return form.resetFields();
        }

        for (let i = 0; i < brandPackagingTypeMappingRecords.length; i++) {
          let filteredRecord = packagingData.data.filter(
            (type) =>
              type.packagingMaterialListId ===
              brandPackagingTypeMappingRecords[i].packagingMaterialListId
          );
          if (filteredRecord.length > 0) {
            formItems.push(
              <Col span={8}>
                <Text strong>
                  {brandPackagingTypeMappingRecords[i].packagingMaterial}:
                </Text>
                {filteredRecord[0].totalBalance}
              </Col>
            );
          } else {
            formItems.push(
              <Col span={8}>
                <Text strong>
                  {brandPackagingTypeMappingRecords[i].packagingMaterial}:
                </Text>
                0
              </Col>
            );
          }
        }
        setBalancePackaging(formItems);
      }
    }
  }, [packagingData]);

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Tea Processing added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Tea Processing updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const validationForPackagingMaterial = (values) => {
    let brandPackagingTypeMappingRecords = brandPackagingTypeMapping.filter(
      (type) =>
        type.brandPackagingQuantityId === values.brandPackagingQuantityId
    );
    for (let i = 0; i < brandPackagingTypeMappingRecords.length; i++) {
      let filteredRecord = packagingData.data.filter(
        (type) =>
          type.packagingMaterialListId ===
          brandPackagingTypeMappingRecords[i].packagingMaterialListId
      );
      if (filteredRecord.length > 0) {
        let packagingRequired = 0;
        let damagedPackaging = values.damagedPackaging.find(
          (record) => record.id === brandPackagingTypeMappingRecords[i].packagingMaterialListId
        ).value;

        let packagingMaterialListRecord = packagingMaterialList.find(
          (record) => record.id === brandPackagingTypeMappingRecords[i].packagingMaterialListId)

        if (packagingMaterialListRecord.isOuterPackaging === 1) {
          packagingRequired =
            damagedPackaging + values.processedStockInNumberOfBags;
        } else if (packagingMaterialListRecord.isPudaPackaging === 1) {
          packagingRequired =
            damagedPackaging + values.processedStockInNumberOfPude;
        } else {
          packagingRequired = damagedPackaging + values.processedStock;
        }
        if (packagingRequired > filteredRecord[0].totalBalance) {
          message.error(
            `Not enough Packaging Material ${brandPackagingTypeMappingRecords[i].packagingMaterial} 
              Balance -${filteredRecord[0].totalBalance} Required Packaging-${packagingRequired}`
          );
          setProcessedBag([]); //reset processed bags
          setDamagedPackaging([]);
          setBalancePackaging([]);
          setInwardBagConvesionForBrand([]);
          form.resetFields();
          return false;
        }
      } else {
        message.error(
          `Not enough Packaging Material-
            ${brandPackagingTypeMappingRecords[i].packagingMaterial}. Balance -0`
        );
        setProcessedBag([]); //reset processed bags
        setDamagedPackaging([]);
        setBalancePackaging([]);
        setInwardBagConvesionForBrand([]);
        form.resetFields();
        return false;
      }
    }
    return true;
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        const damagedPackaging = [];
        let brandPackagingQuantityRecord = brandPackagingQuantity.find(
          (type) => type.id === values.brandPackagingQuantityId
        );

        let brandPackagingTypeMappingArr = brandPackagingTypeMapping.filter(
          (brandPackagingType) =>
            brandPackagingType.brandPackagingQuantityId ===
            values.brandPackagingQuantityId
        );

        brandPackagingTypeMappingArr.forEach((item) => {
          damagedPackaging.push({ id: item.packagingMaterialListId, value: values[item.id] });
          delete values[item.id];
        });

        values.damagedPackaging = damagedPackaging;
        values.brandId = brandPackagingQuantityRecord.brandId;

        let processedStock = Math.floor(
          ((values.processedBags * values.numberOfKgsPerBag[0] -
            values.teaWastage) *
            1000) /
          brandPackagingQuantityRecord.packagingGrammage
        );
        values.processedStock = processedStock;
        if (!isEmpty(sachetSetting)) {
          var sachetSettingRecord = sachetSetting.find(
            (record) =>
              record.brandPackagingQuantityId ===
              values.brandPackagingQuantityId
          );
        }
        if (!isEmpty(bagConversion)) {
          var bagConversionRecord = bagConversion.find(
            (record) =>
              record.brandPackagingQuantityId ===
              values.brandPackagingQuantityId
          );
        }

        if (!isEmpty(sachetSettingRecord)) {
          var pude = 0;
          var bags = 0;
          var pouchesInPuda = sachetSettingRecord.pouchesInPuda;
          var extraPouchesInPuda = !isEmpty(
            sachetSettingRecord.extraPouchesInPuda
          )
            ? sachetSettingRecord.extraPouchesInPuda
            : 0;
          var pudeInBag = sachetSettingRecord.pudeInBag;
          var extraPouchesInBag = !isEmpty(
            sachetSettingRecord.extraPouchesInBag
          )
            ? sachetSettingRecord.extraPouchesInBag
            : 0;


          var packetsInOneBag =
            (pouchesInPuda + extraPouchesInPuda) * pudeInBag +
            extraPouchesInBag;
          while (processedStock > packetsInOneBag) {
            processedStock = processedStock - packetsInOneBag;
            pude = pude + pudeInBag;
          }
          pude =
            pude + processedStock / (pouchesInPuda + extraPouchesInPuda);
          bags = values.processedStock / packetsInOneBag;
          values.processedStockInNumberOfBags = bags;
          values.processedStockInNumberOfPude = pude;
          if (validationForPackagingMaterial(values)) {
            if (
              !Number.isInteger(values.processedStockInNumberOfBags) ||
              !Number.isInteger(values.processedStockInNumberOfPude)
            ) {
              confirm({
                title: "Do you want to continue?",
                content: `Outer packaging being used is ${values.processedStockInNumberOfBags
                  } unit(s), The system will deduct ${Math.ceil(
                    values.processedStockInNumberOfBags
                  )} unit(s). Puda packaging being used is ${values.processedStockInNumberOfPude
                  } unit(s), The system will deduct ${Math.ceil(
                    values.processedStockInNumberOfPude
                  )} unit(s).`,

                onOk() {
                  values.processedStockInNumberOfBags = Math.ceil(
                    values.processedStockInNumberOfBags
                  );
                  values.processedStockInNumberOfPude = Math.ceil(
                    values.processedStockInNumberOfPude
                  );
                  request(values, API_METHODS.POST);
                },
                onCancel() {
                  return false;
                },
              });
            } else {
              request(values, API_METHODS.POST);
            }
          }
        } else if (!isEmpty(bagConversionRecord)) {
          let numberOfKgsPerBag = bagConversionRecord.numberOfKgsPerBag;

          values.processedStockInNumberOfBags =
            (processedStock * brandPackagingQuantityRecord.packagingGrammage) /
            1000 /
            numberOfKgsPerBag;

          if (validationForPackagingMaterial(values)) {
            if (!Number.isInteger(values.processedStockInNumberOfBags)) {
              confirm({
                title: "Do you want to continue?",
                content: `Outer packaging being used is ${values.processedStockInNumberOfBags
                  } unit(s), The system will deduct ${Math.ceil(
                    values.processedStockInNumberOfBags
                  )} unit(s).`,
                onOk() {
                  values.processedStockInNumberOfBags = Math.ceil(
                    values.processedStockInNumberOfBags
                  );
                  request(values, API_METHODS.POST);
                },
                onCancel() {
                  return false;
                },
              });
            } else {
              request(values, API_METHODS.POST);
            }
          }
        }
      }
    });
  };
  const handleCancel = () => {
    close();
  };

  const customerValidaorForProcessedBag = (value, numberOfKgsPerBag) => {
    if (value <= numberOfKgsPerBag[1]) {
      return true;
    } else {
      return false;
    }
  };

  const setDynamicData = (value) => {
    setProcessedBag([]); //reset processed bags
    form.resetFields();
    form.setFieldsValue({ brandPackagingQuantityId: value });
    getPackagingRequest(); //packaging

    //if inward bag data is empty
    if (isEmpty(inwardBagsData.data)) {
      message.error("Please add Inward Bags Data.");
      setDamagedPackaging([]);
      setBalancePackaging([]);
      setInwardBagConvesionForBrand([]);
      return form.resetFields();
    }

    //if bagConversionRecord and sachetSettingRecord not found
    if (!isEmpty(bagConversion)) {
      var bagConversionRecord = bagConversion.find(
        (type) => type.brandPackagingQuantityId === value
      );
    }
    if (!isEmpty(sachetSetting)) {
      var sachetSettingRecord = sachetSetting.find(
        (type) => type.brandPackagingQuantityId === value
      );
    }
    if (isEmpty(bagConversionRecord) && isEmpty(sachetSettingRecord)) {
      message.error(
        "Please add bag conversion or sachet setting for selected packaging."
      );
      setDamagedPackaging([]);
      setBalancePackaging([]);
      setInwardBagConvesionForBrand([]);
      return form.resetFields();
    }

    //Get Inward Bag stock for selected brand id
    let brandPackagingQuantityRecord = brandPackagingQuantity.find(
      (record) => record.id === value
    );

    if (isEmpty(brandPackagingQuantityRecord)) {
      message.error(
        "Please add Brand Packaging Quantity for selected packaging."
      );
      setDamagedPackaging([]);
      setBalancePackaging([]);
      setInwardBagConvesionForBrand([]);
      return form.resetFields();
    }
    let inwardBagsStockDataForSelectedBrand = inwardBagsData.data.filter(
      (record) => record.brandId === brandPackagingQuantityRecord.brandId
    );

    let radio = [];
    if (inwardBagsStockDataForSelectedBrand.length === 0) {
      message.error("Please add inward bags for selected packaging.");
      setDamagedPackaging([]);
      setBalancePackaging([]);
      setInwardBagConvesionForBrand([]);
      return form.resetFields();
    }
    for (let i = 0; i < inwardBagsStockDataForSelectedBrand.length; i++) {
      radio.push(
        <Radio
          value={[
            inwardBagsStockDataForSelectedBrand[i].numberOfKgsPerBag,
            inwardBagsStockDataForSelectedBrand[i].stock,
          ]}
          key={i}
        >
          {inwardBagsStockDataForSelectedBrand[i].numberOfKgsPerBag} - Stock -
          {inwardBagsStockDataForSelectedBrand[i].stock}
        </Radio>
      );
    }

    setInwardBagConvesionForBrand(
      <>
        <Form.Item
          name="numberOfKgsPerBag"
          label="Number Of Kgs Per Bag"
          rules={[
            { required: true, message: "Number Of Kgs Per Bag is required." },
          ]}
        >
          <Radio.Group
            onChange={() => {
              setProcessedBag(
                //set processed bags Input dynamically after selecting number of kgs per bag
                <>
                  <Form.Item
                    name="processedBags"
                    label="Processed Bags"
                    rules={[
                      {
                        required: true,
                        message: "Processed Bags are required.",
                      },
                      // { min: 1, message: "Processed Bags should be atleast" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            customerValidaorForProcessedBag(
                              value,
                              getFieldValue("numberOfKgsPerBag")
                            ) // customer validator for Processed Bags (should be always less than
                            // selected number of kgs per bag stock)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Processed Bags should be less than or equal to stock"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber placeholder="Processed Bags" min={1} />
                  </Form.Item>
                </>
              );
              form.setFieldsValue({ processedBags: 1 }); // setting processed bags = 1(min processed bags) if radio selection is changed(numberOfKgsPerBag)
            }}
          >
            <Space direction="vertical">{radio}</Space>
          </Radio.Group>
        </Form.Item>
      </>
    );

    //get packaging material required for given brandPackagingQuantityId and set dynamic inputs for damaged packaging
    const formItems = [];
    let brandPackagingTypeMappingArr = brandPackagingTypeMapping.filter(
      (brandPackagingType) =>
        brandPackagingType.brandPackagingQuantityId === value
    );
    let initialval = {};
    for (let i = 0; i < brandPackagingTypeMappingArr.length; i++) {
      formItems.push(
        <Col span={8}>
          <Form.Item
            name={brandPackagingTypeMappingArr[i].id}
            label={brandPackagingTypeMappingArr[i].packagingMaterial}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      );
      initialval[brandPackagingTypeMappingArr[i].id] = 0;
    }

    form.setFieldsValue(initialval);
    setDamagedPackaging(formItems);
  };

  return (
    <>
      {brandPackagingQuantity !== BLANK_VALUE &&
        brandPackagingTypeMapping !== BLANK_VALUE &&
        (bagConversion !== BLANK_VALUE || sachetSetting !== BLANK_VALUE) ? (
        <Modal
          title="Packaging Production"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          okText="Send To Packaging"
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={{ processedDate: moment() }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="brandPackagingQuantityId"
                  label="Packaging"
                  rules={[
                    { required: true, message: "Packaging Type is required." },
                  ]}
                >
                  <Select
                    placeholder="Select Option"
                    onChange={setDynamicData}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {brandPackagingQuantity.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {getBrandPackagingName(option)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="teaWastage"
                  label="Tea wastage (In KG)"
                  rules={[
                    { required: true, message: "Tea wastage is required." },
                  ]}
                >
                  <InputNumber placeholder="Tea wastage" min={0} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="processedDate"
                  label="Date"
                  rules={[{ required: true, message: "Date is required." }]}
                >
                  <DatePicker placeholder="Processed Date" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>{inwardBagConvesionForBrand}</Col>
              <Col span={8}> {processedBag}</Col>
            </Row>

            {damagedPackaging.length > 0 && (
              <>
                <Divider>Damaged Packaging</Divider>
                <Row gutter={16}>{damagedPackaging}</Row>
              </>
            )}
            {balancePackaging.length > 0 && (
              <>
                <Divider>Packaging Balance</Divider>
                <Row gutter={16}>{balancePackaging}</Row>
              </>
            )}
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1000}
          onCancel={handleCancel}
          footer={<Button onClick={handleCancel}>Cancel</Button>}
        >
          <Result
            status="error"
            title="Please add Brand Packaging Quantity,Bag Conversion,Brand Packaging Type Mapping"
          />
        </Modal>
      )}
    </>
  );
}

export default AddTeaProcessing;
