import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message, Select, Switch } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { replaceBlankValueToUndefined } from "../../../generalFunctions";
const { Option } = Select;
function AddPackagingMaterialList(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi(
    "/config/packagingMaterialList",
    null,
    API_METHODS.POST
  );
  const [editResponse, editRequest] = useApi(
    `/config/packagingMaterialList/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Packaging Material added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Packaging Material updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      let initialValues = { ...selectedRecord };
      initialValues = replaceBlankValueToUndefined(initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        request(values, API_METHODS.POST);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title="Add Packaging Material"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="packagingMaterial"
              label="Packaging Material"
              rules={[
                { required: true, message: "Packaging Material is required." },
                {
                  max: 50,
                  message:
                    "Packaging Material should be maximum of 50 characters.",
                },
              ]}
            >
              <Input placeholder="Packaging Material" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="unit"
              label="Unit"
              rules={[{ required: true, message: "Unit is required." }]}
            >
              <Select placeholder="Unit" allowClear>
                <Option value="unit(s)">unit(s)</Option>
                <Option value="Kg">Kg</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="isOuterPackaging"
              label="Outer Packaging"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="isPudaPackaging"
              label="Puda Packaging"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddPackagingMaterialList;
