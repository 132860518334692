import { Button, Col, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { displayFormattedDate, getClientConfig, getPackagingInvetoryBalance, getPackagingMaterialName, getPackagingUnit, isEmpty } from '../../generalFunctions';
import useApi from '../../hooks/useApi';
import TableComponent from '../common/table/TableComponent';
import AddPackagingMaterial from './AddPackagingMaterial';
import ProcessedEntries from './ProcessedEntries';

const PackagingMaterialTable = () => {
    const { brandPackagingQuantity, brandPackagingTypeMapping } = getClientConfig();
    const getFilters = () => {
        const filter = [];
        for (let i = 0; i < brandPackagingQuantity.length; i++) {
            filter.push({ text: `${brandPackagingQuantity[i].brand} - ${brandPackagingQuantity[i].typeOfPackaging} - ${brandPackagingQuantity[i].packagingGrammage}`, value: brandPackagingQuantity[i].id });
        }
        return filter;
    };

    const getFilteredRecords = (key, record) => {
        const brandPackagingTypeMappingRecords = brandPackagingTypeMapping.filter(type => type.brandPackagingQuantityId === key);
        for (let i = 0; i < brandPackagingTypeMappingRecords.length; i++) {
            if (brandPackagingTypeMappingRecords[i].packagingMaterialListId === record.packagingMaterialListId) {
                return true
            }
        }
    };

    const columns = [
        {
            title: "Packaging",
            dataIndex: 'packagingMaterialListId',
            render: (text) => getPackagingMaterialName(text),
            filters: getFilters(),
            onFilter: (key, record) => getFilteredRecords(key, record),
        },
        {
            title: "Quantity",
            dataIndex: 'purchasedQuantity',
            sorter: true,
            render: (text, record) => <>{text + " " + getPackagingUnit(record.packagingMaterialListId)}</>
        },
        {
            title: "Balance",
            dataIndex: 'balance',
            render: (text, record) => getPackagingInvetoryBalance(text, record),
            sorter: true

        },
        {
            title: "Inward Date",
            dataIndex: 'purchaseDate',
            sorter: true,
            render: (text) => displayFormattedDate(text)
        },
        {
            title: "Rate",
            dataIndex: 'rate',
            sorter: true
        },
        {
            title: "Amount",
            dataIndex: 'amount',
            sorter: true
        },
        {
            title: "GST",
            dataIndex: 'gst',
            sorter: true
        },
        {
            title: "Transport",
            dataIndex: 'transport',
            sorter: true
        },
        {
            title: "Total Amount",
            dataIndex: 'totalAmount',
            sorter: true,
            render: (text, record) => calculateTotalAmount(record)
        },
        {
            title: 'Action',
            key: 'action',
            fixed: "right",
            render: (text, record) => <Space>
                <Button size='small' onClick={() => editRecord(record)}>Edit</Button>
                <Button size='small' onClick={() => viewProcessedEntries(record)}>View Processed Entries</Button>
            </Space>
        }
    ];
    const [responseData, getRequest] = useApi("/packaging", null, API_METHODS.GET);
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleProcessedEntries, setVisibleProcessedEntries] = useState(false);
    const [tableData, setTableData] = useState();
    const [selectedRecord, setSelectedRecord] = useState();

    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setTableData(data);
    }, [responseData]);

    const handleAddPackagingMaterial = (isUpdated) => {
        const flag = visible ? false : true;
        setVisible(flag);
        isUpdated === true && getRequest();
    }

    const handleEditPackagingMaterial = (isUpdated) => {
        const flag = visibleEdit ? false : true;
        !flag && setSelectedRecord(null);
        setVisibleEdit(flag);
        isUpdated === true && getRequest();
    }

    const editRecord = (record) => {
        setVisibleEdit(true);
        setSelectedRecord(record);
    }

    const viewProcessedEntries = (record) => {
        setVisibleProcessedEntries(true);
        setSelectedRecord(record);
    }

    const handleProcessedEntries = (isUpdated) => {
        const flag = visibleProcessedEntries ? false : true;
        setVisibleProcessedEntries(flag);
        isUpdated === true && getRequest();
    }

    const calculateTotalAmount = (selectedRecord) => {
        const { amount, gst, transport } = selectedRecord;
        const gstAmount = !isEmpty(gst) ? (amount * (gst / 100)) : 0;
        let totalAmount = (!isEmpty(amount) ? amount : 0) + (!isEmpty(gstAmount) ? gstAmount : 0) + (!isEmpty(transport) ? transport : 0);
        return totalAmount;
    }

    return <>
        <Row>
            <Col span={6}>
                <Typography.Title level={5}>Packaging Material</Typography.Title>
            </Col>
            <Col span={18}>
                <Space style={{ marginBottom: 10, float: "right" }}>
                    <Button type='primary' onClick={handleAddPackagingMaterial}>Add Packaging Material</Button>
                    {/* <Button type='primary' onClick={handleSendToProcess}>Send to Process</Button> */}
                </Space>
            </Col>
        </Row>
        <TableComponent columns={columns} tableData={tableData} loading={responseData.isLoading} />
        {visible && <AddPackagingMaterial visible={visible} close={handleAddPackagingMaterial} />}
        {visibleEdit && <AddPackagingMaterial visible={visibleEdit} selectedRecord={selectedRecord} close={handleEditPackagingMaterial} />}
        {visibleProcessedEntries && <ProcessedEntries visible={visibleProcessedEntries} packagingPurchaseId={selectedRecord && selectedRecord.id} packagingName={selectedRecord && getPackagingMaterialName(selectedRecord.packagingMaterialListId)} close={handleProcessedEntries} />}
    </>
}

export default PackagingMaterialTable;