import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Col, Row, Divider, Select, DatePicker, InputNumber, message, Switch, Result, Button } from 'antd';
import { formatDateToMoment, getClientConfig, isEmpty, replaceBlankValueToUndefined, replaceNull, replaceUndefinedToNull } from '../../generalFunctions';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import useApi from '../../hooks/useApi';
import moment from 'moment';

const AddRawMaterial = (props) => {
    const [form] = Form.useForm();
    const { visible, close, selectedRecord } = props;
    const [purchaseType, setPurchaseType] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [response, request] = useApi("/rawMaterial/purchase", null, API_METHODS.POST);
    const [editResponse, editRequest] = useApi(`/rawMaterial/purchase/${selectedRecord && selectedRecord.id}`, null, API_METHODS.PUT);
    const { purchaseTypes, teaGrades, grades, brands } = getClientConfig();

    useEffect(() => {
        if (response.data) {
            close(true);
            message.success('Purchase added successfully.');
        }
    }, [response]);

    useEffect(() => {
        if (editResponse.data) {
            close(true);
            message.success('Purchase updated successfully.');
        }
    }, [editResponse]);

    useEffect(() => {
        if (selectedRecord) {
            setPurchaseType(checkPerchaseType(selectedRecord.purchaseType));
            let initialValues = { ...selectedRecord };
            initialValues = replaceBlankValueToUndefined(initialValues);
            initialValues.receivedDate = formatDateToMoment(initialValues.receivedDate);
            initialValues.purchaseDate = formatDateToMoment(initialValues.purchaseDate);
            form.setFieldsValue(initialValues);
            calculateTotalCharges(initialValues);
        }
    }, [selectedRecord]);

    const handleOk = () => {
        form.validateFields().then(values => {
            if (selectedRecord) {
                editRequest(replaceUndefinedToNull(values));
            } else {
                values.balance = values.quantity;
                request(values, API_METHODS.POST);
            }
        })
    };

    const handleCancel = () => {
        close();
    };

    const setSelectedPurchaseType = (value) => {
        setPurchaseType(checkPerchaseType(value));
    }

    const calculateTotalCharges = (selectedRecord) => {
        const { amount, gst, transport } = selectedRecord ? selectedRecord : form.getFieldsValue();
        let totalAmount = (amount ? amount : 0) + (gst ? gst : 0) + (!isEmpty(transport) ? transport : 0);
        setTotalAmount(totalAmount);
    }

    const calculateAmount = (selectedRecord) => {
        const { quantityInKg, rate } = selectedRecord ? selectedRecord : form.getFieldsValue();
        let totalAmount = quantityInKg * rate;
        form.setFieldsValue({ "amount": totalAmount });
        calculateGST(selectedRecord);
    }

    const calculateGST = (selectedRecord) => {
        const { amount } = selectedRecord ? selectedRecord : form.getFieldsValue();
        let gst = amount * 0.05;
        form.setFieldsValue({ "gst": gst });
        calculateTotalCharges(selectedRecord);
    }

    const checkPerchaseType = (typeId) => {
        const type = purchaseTypes.find(t => t.id === typeId).purchaseType;
        return type;
    }

    return (
        <>
            {purchaseTypes !== BLANK_VALUE && teaGrades !== BLANK_VALUE && grades !== BLANK_VALUE ? (
                <Modal title="Add Raw Material" visible={visible} onOk={handleOk} onCancel={handleCancel} width={1000} okText="Save">
                    <Form layout="vertical" form={form} initialValues={{ purchaseDate: moment() }}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item name="purchaseType" label="Purchased From" rules={[{ required: true, message: "Purchased From is required." }]}>
                                    <Select placeholder="Select Option" onChange={setSelectedPurchaseType} allowClear showSearch filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                        {purchaseTypes.map(option =>
                                            <Select.Option key={option.id} value={option.id}>{option.purchaseType}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="receivedDate" label="Received Date" rules={[{ required: true, message: "Received Date is required." }]}>
                                    <DatePicker placeholder="Received Date" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="gardenAuctionName" label="Garden Name / Auction Code" rules={[{ required: true, message: "Garden Name / Auction Code is required." }]}>
                                    <Input placeholder="Garden Name / Auction Code" />
                                </Form.Item>

                            </Col>
                        </Row>
                        <> {purchaseType &&
                            <>
                                <Divider orientation="left">{purchaseType + " Information"}</Divider>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item name="numberOfBags" label="Number of Bags" rules={[{ required: true, message: "Number of Bags are required." }]}>
                                            <InputNumber placeholder="Number of Bags" onChange={() => { calculateTotalCharges(); calculateAmount() }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="quantityInKg" label="Quantity (kg)" rules={[{ required: true, message: "Quantity is required." }]}>
                                            <InputNumber placeholder="Quantity" onChange={() => { calculateTotalCharges(); calculateAmount() }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="kgPerBag" label="Kg per Bag">
                                            <InputNumber placeholder="Kg per Bag" />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row gutter={16}>
                                    {purchaseType.toLowerCase().includes("garden") && <Col span={8}>
                                        <Form.Item name="invoiceNumber" label="Invoice Number">
                                            <Input placeholder="Invoice Number" />
                                        </Form.Item>
                                    </Col>}
                                    {purchaseType.toLowerCase().includes("auction") && <Col span={8}>
                                        <Form.Item name="brandId" label="Brand">
                                            <Select placeholder="Select Option" allowClear showSearch filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {brands.map(option =>
                                                    <Select.Option key={option.id} value={option.id}>{option.brand}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>}
                                    <Col span={8}>
                                        <Form.Item name="gardenTeaGrade" label="Tea Grade">
                                            <Select placeholder="Select Option" allowClear showSearch filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {teaGrades.map(option =>
                                                    <Select.Option key={option.id} value={option.id}>{option.teaGrade}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider orientation="left">Rate Information</Divider>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item name="rate" label="Rate" rules={[{ required: true, message: "Rate is required." }]}>
                                            <InputNumber placeholder="Rate" onChange={() => { calculateTotalCharges(); calculateAmount() }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="amount" label="Amount" rules={[{ required: true, message: "Amount is required." }]}>
                                            <InputNumber placeholder="Amount" onChange={() => { calculateTotalCharges(); calculateGST() }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="gst" label="GST" rules={[{ required: true, message: "GST is required." }]}>
                                            <InputNumber placeholder="GST" onChange={() => calculateTotalCharges()} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item name="transport" label="Transport Charges">
                                            <InputNumber placeholder="Transport" onChange={() => calculateTotalCharges()} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Total Amount">
                                            <h4>{totalAmount}</h4>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider orientation="left">Other Information</Divider>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item name="purchaseDate" label="Purchase Date">
                                            <DatePicker placeholder="Purchase Date" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="qualityTeaGrade" label="Quality Grade">
                                            <Select placeholder="Select Option" allowClear showSearch filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {grades.map(option =>
                                                    <Select.Option key={option.id} value={option.id}>{option.grade}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {/* <Col span={8}>
                                <Form.Item name="updatePurchaseRecord" label="Update Master Record" valuePropName='checked'>
                                    <Switch />
                                </Form.Item>
                            </Col> */}
                                </Row>
                            </>}</>
                    </Form>
                </Modal>) : (
                <Modal
                    visible={visible}
                    width={1000}
                    onCancel={handleCancel}
                    footer={<Button onClick={handleCancel}>Cancel</Button>}
                >
                    <Result status="error" title="Please add Purchase Types, Tea Grades, Grades" />
                </Modal>
            )}
        </>
    );
};

export default AddRawMaterial;