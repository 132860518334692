import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Col,
  Row,
  message,
  Select,
  Result,
  Button,
} from "antd";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { getClientConfig } from "../../../generalFunctions";

function AddUser(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord, edit } = props;
  const [response, request] = useApi("/config/user", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/user/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );
  const { roles } = getClientConfig();

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("User added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("User updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        request(values, API_METHODS.POST);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <>
      {roles !== BLANK_VALUE ? (
        <Modal
          title="Add User"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          okText="Save"
        >
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[
                    { required: true, message: "Username is required." },
                    {
                      max: 25,
                      message: "Username should be maximum of 25 characters.",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>
              </Col>
              {!edit && (
                <Col span={8}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { required: true, message: "Password is required." },
                      {
                        max: 20,
                        message: "Password should be maximum of 20 characters.",
                      },
                    ]}
                  >
                    <Input placeholder="Password" />
                  </Form.Item>
                </Col>
              )}
              <Col span={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Email is required." },
                    {
                      max: 100,
                      message: "Email should be maximum of 100 characters.",
                    },
                    {
                      type: "email",
                      message: "Email is not a valid Email.",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[{ required: true, message: "Role is required." }]}
                >
                  <Select
                    placeholder="Select Role"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {roles.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.role}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1000}
          onCancel={handleCancel}
          footer={<Button onClick={handleCancel}>Cancel</Button>}
        >
          <Result status="error" title="Please add roles" />
        </Modal>
      )}
    </>
  );
}

export default AddUser;
