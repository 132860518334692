import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";

function AddGrade(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi("/config/grade", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/grade/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Grade added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Grade updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        request(values, API_METHODS.POST);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title="Add Grade"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8} offset={8}>
            <Form.Item
              name="grade"
              label="Grade"
              rules={[
                { required: true, message: "Grade is required." },
                {
                  max: 50,
                  message: "Grade should be maximum of 50 characters.",
                },
              ]}
            >
              <Input placeholder="Grade" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddGrade;
