import { Button, Skeleton, Space, Table } from 'antd';
import { max } from 'moment';
import React, { useEffect, useState } from 'react';
import { BLANK_VALUE } from '../../../constants';
import { CSVLink } from "react-csv";
import { isEmpty } from '../../../generalFunctions';

const TableComponent = (props) => {
    const [columns, setColumns] = useState();
    const { tableData, loading, rowKey, downloadData, headers, filename } = props;


    useEffect(() => {
        if (props.columns) {
            setColumns(props.columns);
        }
    }, [props.columns]);

    const clientSideSorting = (pagination, filters, sorter) => {
        if (sorter.field) {
            let columns = [...props.columns];
            let index = sorter.column === undefined ? sorter.dataIndex : sorter.column.dataIndex;
            let sortColumn = columns.find(c => c.dataIndex === index);
            sortColumn = sorter;
            if (sortColumn.column && !sortColumn.column.useOwnSort) {
                sortColumn.column.sorter = (a, b) => {
                    if (a[sortColumn.field] === undefined)
                        a[sortColumn.field] = BLANK_VALUE;
                    if (b[sortColumn.field] === undefined) {
                        b[sortColumn.field] = BLANK_VALUE;
                    }
                    return (a[sortColumn.field].toString()).localeCompare(b[sortColumn.field].toString())
                };
            }
            setColumns(columns);
        }
    }

    const isDownloadOptionEnabled = () => {
        if (props.showDownloadOption === null || props.showDownloadOption === undefined ||
            props.showDownloadOption === BLANK_VALUE || props.showDownloadOption === false) {
            return false;
        }
        else if (props.showDownloadOption === true) {
            return true
        }
        return false;
    }

    return !loading ? (
        <Space direction='vertical' size={24} style={{ width: "100%" }}>
            {downloadData && isDownloadOptionEnabled() && (
                <Button type='primary' style={{ float: "right" }}>
                    <CSVLink data={downloadData} headers={headers} filename={filename}>
                        Download Report
                    </CSVLink>
                </Button>
            )}
            <Table
                rowKey={rowKey ? rowKey : "id"}
                columns={columns}
                dataSource={tableData}
                onChange={clientSideSorting}
                pagination={!isEmpty(props.pagination) ? props.pagination : { pageSize: 25 }}
                style={{ width: "100%" }}
                scroll={{ x: "max-content", y: window.innerHeight * 0.7 }}
                tableLayout="auto"
            />

        </Space>
    ) : (
        <Skeleton />
    );
}

export default TableComponent;