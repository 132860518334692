import { Card, Col, Row, Space, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { getClientConfig, getPackagingByBrandPackagingQuantityId, isEmpty, sumOfArrayObjectValuesWithSameKey } from '../../generalFunctions';
import useApi from '../../hooks/useApi';
import BarGraph from './graphs/BarGraph';
import Donut from './graphs/Donut';
import PieChart from './graphs/PieChart';

const Dashboard = (props) => {
    const [responseDataDashboard, getRequestDashboard] = useApi("/dashboard", null, API_METHODS.GET);
    const [responseData, getRequest] = useApi("/teaInventory/inventory", null, API_METHODS.GET);
    const [responseDataPackaging, getRequestPackaging] = useApi("/packaging/inventory", null, API_METHODS.GET);
    const [responseDataRawMaterialInventory, getRequestRawMaterialInventory] = useApi("/rawMaterial/inventory", null, API_METHODS.GET);
    const [readyStock, setReadyStock] = useState();
    const [packagingStock, setPackagingStock] = useState();
    const [gradeWiseRawMaterialStock, setGradeWiseRawMaterialStock] = useState();
    const [teaGradeWiseRawMaterialStock, setTeaGradeWiseRawMaterialStock] = useState();
    const [dashboardData, setDashboardData] = useState();

    useEffect(() => {
        getRequest();
        getRequestPackaging();
        getRequestRawMaterialInventory();
        getRequestDashboard();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        if (!isEmpty(data)) {
            let readyStock = data.map(item => ({
                type: getPackagingByBrandPackagingQuantityId(item.brandPackagingQuantityId),
                value: item.stock
            }))
            setReadyStock(readyStock);
        }
    }, [responseData]);

    useEffect(() => {
        const data = responseDataPackaging.data !== BLANK_VALUE ? responseDataPackaging.data : [];
        if (!isEmpty(data)) {
            const { packagingMaterialList } = getClientConfig();
            let packagingStock = data.map(item => ({
                type: packagingMaterialList.find((type) => type.id === item.packagingMaterialListId).packagingMaterial,
                value: item.totalBalance
            }))
            setPackagingStock(packagingStock);
        }
    }, [responseDataPackaging]);

    useEffect(() => {
        const data = responseDataRawMaterialInventory.data !== BLANK_VALUE ? responseDataRawMaterialInventory.data : [];
        if (!isEmpty(data)) {
            const { grades, teaGrades } = getClientConfig();
            let gradeWiseRawMaterialStock = data.map(item => ({
                type: !isEmpty(item.qualityTeaGrade) ? grades.find(type => type.id === item.qualityTeaGrade).grade : "",
                value: item.numberOfBags
            }));
            let sum = sumOfArrayObjectValuesWithSameKey(gradeWiseRawMaterialStock);
            setGradeWiseRawMaterialStock(sum);

            let teaGradeWiseRawMaterialStock = data.map(item => ({
                type: !isEmpty(item.gardenTeaGrade) ? teaGrades.find(type => type.id === item.gardenTeaGrade).teaGrade : "",
                value: item.numberOfBags
            }));
            sum = sumOfArrayObjectValuesWithSameKey(teaGradeWiseRawMaterialStock);
            setTeaGradeWiseRawMaterialStock(sum);
        }
    }, [responseDataRawMaterialInventory]);

    useEffect(() => {
        const data = responseDataDashboard.data !== BLANK_VALUE ? responseDataDashboard.data : [];
        if (!isEmpty(data)) {
            setDashboardData(data);
        }
    }, [responseDataDashboard]);

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            <Row gutter={48}>
                <Col span={8}>
                    <Card loading={!dashboardData}>
                        <Statistic
                            title={<h4>Raw Material Stock</h4>}
                            value={dashboardData && dashboardData.rawMaterialStock !== BLANK_VALUE ? dashboardData.rawMaterialStock : 0}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="Bags"
                        />
                    </Card >
                </Col>
                {/* <Col span={6}>
                    <Card loading={!dashboardData}>
                        <Statistic
                            title={<h4>Raw Material Processed (yesterday)</h4>}
                            value={dashboardData && dashboardData.processedRawMaterial !== BLANK_VALUE ? dashboardData.processedRawMaterial : 0}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="Kg"
                        />
                    </Card >
                </Col> */}
                <Col span={8}>
                    <Card loading={!dashboardData}>
                        <Statistic
                            title={<h4>Tea Processed (yesterday)</h4>}
                            value={dashboardData && dashboardData.processedTea !== BLANK_VALUE ? dashboardData.processedTea : 0}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="Bags"
                        />
                    </Card >
                </Col>
                <Col span={8}>
                    <Card loading={!dashboardData}>
                        <Statistic
                            title={<h4>Tea Wastage (yesterday)</h4>}
                            value={dashboardData && dashboardData.teaWastage !== BLANK_VALUE ? dashboardData.teaWastage : 0}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="Kg"
                        />
                    </Card >
                </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
                <Col span={24}>
                    <Card title="Packaging Material with Low Stock" loading={isEmpty(packagingStock)}>
                        {packagingStock && <BarGraph data={packagingStock} />}
                    </Card>
                </Col>
            </Row>
            <Row gutter={48} style={{ paddingTop: 20 }}>
                <Col span={8}>
                    <Card title="Ready to Dispatch Stock" loading={isEmpty(readyStock)}>
                        {readyStock && <Donut data={readyStock} />}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Tea Grade wise Raw Material (in bags)" loading={isEmpty(teaGradeWiseRawMaterialStock)}>
                        {teaGradeWiseRawMaterialStock && <PieChart data={teaGradeWiseRawMaterialStock} />}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Grade wise Raw Material (in bags)" loading={isEmpty(gradeWiseRawMaterialStock)}>
                        {gradeWiseRawMaterialStock && <PieChart data={gradeWiseRawMaterialStock} />}
                    </Card>
                </Col>
            </Row>
        </Space>
    );
}

export default Dashboard;