import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  message,
  InputNumber,
  Select,
  Result,
  Button,
} from "antd";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { getClientConfig } from "../../../generalFunctions";

function AddPackagingInfo(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi(
    "/config/packagingInfo",
    null,
    API_METHODS.POST
  );
  const [editResponse, editRequest] = useApi(
    `/config/packagingInfo/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );
  const { packagingMaterialList } = getClientConfig();

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Packaging Info added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Packaging Info updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        request(values, API_METHODS.POST);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <>
      {packagingMaterialList !== BLANK_VALUE ? (
        <Modal
          title="Add Packaging Info"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          okText="Save"
        >
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="packagingMaterialListId"
                  label="Packaging"
                  rules={[
                    { required: true, message: "Packaging Type is required." },
                  ]}
                >
                  <Select
                    placeholder="Select Option"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {packagingMaterialList.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.packagingMaterial}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="numberOfPacketsPerKg"
                  label="Number of Packets per Kg"
                  rules={[
                    { required: true, message: "Number of Packets per Kg is required." },
                  ]}
                >
                  <InputNumber
                    placeholder="Number of Packets per Kg"
                    step={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1000}
          onCancel={handleCancel}
          footer={<Button onClick={handleCancel}>Cancel</Button>}
        >
          <Result
            status="error"
            title="Please add Brand-Packaging Type Relation"
          />
        </Modal>
      )}
    </>
  );
}

export default AddPackagingInfo;
