import { Button, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import {
  getBrandPackagingName,
  getClientConfig,
} from "../../../generalFunctions";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddBrandPackagingTypeMapping from "./AddBrandPackagingTypeMapping";

function BrandPackagingTypeMappingTable(props) {
  const { brandPackagingQuantity } = getClientConfig();

  const getFilters = () => {
    const filter = [];
    for (let i = 0; i < brandPackagingQuantity.length; i++) {
      filter.push({
        text:
          brandPackagingQuantity[i].brand +
          " - " +
          brandPackagingQuantity[i].packagingGrammage +
          " grams" +
          " - " +
          brandPackagingQuantity[i].typeOfPackaging,
        value: brandPackagingQuantity[i].id,
      });
    }
    return filter;
  };

  const getFilteredRecords = (key, record) => {
    if (key === record.brandPackagingQuantityId) return true;
  };

  const columns = [
    {
      title: "Brand Packaging",
      dataIndex: "brandPackagingQuantityId",
      render: (text, record) => getBrandPackagingName(record),
      filters: getFilters(),
      onFilter: (key, record) => getFilteredRecords(key, record),
    },
    {
      title: "Packaging Material",
      dataIndex: "packagingMaterial",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  const [responseData, getRequest] = useApi(
    "/config/brandPackagingTypeMapping",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedRecord, setSelectedRecord] = useState();

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  const handleAddBrandPackagingTypeMapping = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const handleEditBrandPackagingTypeMapping = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Typography.Title level={5}>
            Brand-Packaging Type Relation
          </Typography.Title>
        </Col>
        <Col span={18}>
          <Space style={{ marginBottom: 10, float: "right" }}>
            <Button type="primary" onClick={handleAddBrandPackagingTypeMapping}>
              Add Brand-Packaging Type Relation
            </Button>
          </Space>
        </Col>
      </Row>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
      />
      {visible && (
        <AddBrandPackagingTypeMapping
          visible={visible}
          close={handleAddBrandPackagingTypeMapping}
        />
      )}
      {visibleEdit && (
        <AddBrandPackagingTypeMapping
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditBrandPackagingTypeMapping}
        />
      )}
    </>
  );
}

export default BrandPackagingTypeMappingTable;
