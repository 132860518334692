import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import {
  displayFormattedDate,
  getPackagingByBrandPackagingQuantityId,
} from "../../generalFunctions";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";

function DispatchedTeaEntries(props) {
  const { visible, close, brandPackagingQuantityId } = props;
  const [responseData, getRequest] = useApi(
    `/teaInventory/dispatchedEntries/${brandPackagingQuantityId}`,
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();

  const columns = [
    // {
    //   title: "Outward Quantity",
    //   dataIndex: "outwardQuantity",
    //   sorter: true,
    // },
    {
      title: "Outward Bags",
      dataIndex: "outwardBags",
      sorter: true,
    },
    {
      title: "Dispatched Date",
      dataIndex: "dispatchDate",
      sorter: true,
      render: (text) => displayFormattedDate(text),
    },
  ];

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  return (
    <Modal
      title={
        "Dispatched Entries for " +
        getPackagingByBrandPackagingQuantityId(brandPackagingQuantityId)
      }
      visible={visible}
      footer={null}
      width={1000}
      onCancel={close}
    >
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
      />
    </Modal>
  );
}

export default DispatchedTeaEntries;
