import "./App.css";
import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Login from "./components/Login";

function App() {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      //if already logged in then redirect to inventory
      navigate("/inventory");
    }
    else {
      //if not logged in redirect to login
      navigate("/")
    }
  }, [token]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/inventory/*" element={<MainLayout />} />
    </Routes>
  );
}

export default App;
