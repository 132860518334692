import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import { getClientConfig } from "../../../generalFunctions";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddUser from "./AddUser";

function UserTable(props) {
  const columns = [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    // },
    {
      title: "User Name",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) => getRole(text),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            Edit
          </Button>
          <Button size="small" onClick={() => updatePasswordRecord(record)}>
            Update Password
          </Button>
          <Button size="small" onClick={() => deleteRecord(record)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  const [responseData, getRequest] = useApi(
    "/config/user",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();
  const [visibleUpdatePassword, setVisibleUpdatePassword] = useState(false);
  const [updatePasswordResponse, updatePasswordRequest] = useApi();
  const [form] = Form.useForm();
  const { roles } = getClientConfig();

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  useEffect(() => {
    if (deleteResponse.data) {
      setVisibleDelete(false);
      setSelectedRecord(null);
      getRequest();
      props.refreshClientConfig();
      message.success("User Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (updatePasswordResponse.data && !updatePasswordResponse.isLoading) {
      setVisibleUpdatePassword(false);
      setSelectedRecord(null);
      getRequest();
      props.refreshClientConfig();
      form.resetFields();
      message.success("User Password updated successfully.");
    }
  }, [updatePasswordResponse]);

  const handleAddUser = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const getRole = (role_id) => {
    const role = roles.find((type) => type.id === role_id);
    return role.role;
  };

  const handleEditUser = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const deleteRecord = (record) => {
    setVisibleDelete(true);
    setSelectedRecord(record);
  };

  const deleteOk = () => {
    if (selectedRecord) {
      deleteRequest(
        null,
        API_METHODS.DELETE,
        `/config/user/${selectedRecord && selectedRecord.id}`
      );
    }
  };

  const handleCancel = () => {
    setVisibleDelete(false);
    setSelectedRecord(null);
  };
  const updatePasswordRecord = (record) => {
    setVisibleUpdatePassword(true);
    setSelectedRecord(record);
  };

  const updatePassword = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        updatePasswordRequest(
          values,
          API_METHODS.PUT,
          `/config/user/password/${selectedRecord && selectedRecord.id}`
        );
      }
    });
  };

  const handleCancelUpdatePassword = () => {
    setVisibleUpdatePassword(false);
    setSelectedRecord(null);
    form.resetFields();
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Typography.Title level={5}>Users</Typography.Title>
        </Col>
        <Col span={18}>
          <Space style={{ marginBottom: 10, float: "right" }}>
            <Button type="primary" onClick={handleAddUser}>
              Add User
            </Button>
          </Space>
        </Col>
      </Row>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
      />
      {visible && (
        <AddUser visible={visible} close={handleAddUser} edit={false} />
      )}
      {visibleEdit && (
        <AddUser
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditUser}
          edit={true}
        />
      )}
      {visibleDelete && (
        <Modal
          title="Delete User"
          visible={visibleDelete}
          onOk={deleteOk}
          onCancel={handleCancel}
          width={700}
          okText="Delete"
        >
          Are you want to delete user - {selectedRecord.username}?
        </Modal>
      )}
      {visibleUpdatePassword && (
        <Modal
          title={`Update Password for- ${selectedRecord.username}`}
          visible={visibleUpdatePassword}
          onOk={updatePassword}
          onCancel={handleCancelUpdatePassword}
          width={700}
          okText="Update Password"
          destroyOnClose={true}
        >
          <Form layout="vertical" form={form}>
            <Row>
              <Col offset={4} span={12}>
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    { required: true, message: "New Password is required." },
                    {
                      max: 20,
                      message:
                        "New Password should be maximum of 20 characters.",
                    },
                  ]}
                >
                  <Input placeholder="Password" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default UserTable;
