import { Col, Row, Form, DatePicker, Space, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { calculateTotalAmount, displayFormattedDate, getClientConfig, getFilterValues, getPurchaseTypeFilterValues, getQualityGradeFilterValues, getTeaGradeFilterValues, isEmpty } from '../../generalFunctions';
import useApi from '../../hooks/useApi';
import TableComponent from '../common/table/TableComponent';
import moment from 'moment';

const { RangePicker } = DatePicker;

const RawMaterialPurchaseReport = (props) => {
    const { purchaseTypes, teaGrades, grades } = getClientConfig();
    const [responseData, getRequest] = useApi("/report/rawMaterialPurchase", null, API_METHODS.GET);
    const [tableData, setTableData] = useState();
    const [nonFilteredTableData, setNonFilteredTableData] = useState();
    const [form] = Form.useForm();
    const columns = [
        {
            title: "Purchase Type",
            dataIndex: 'purchaseType',
            sorter: true,
            render: (text) => purchaseTypes.find(type => type.id === text).purchaseType,
            filters: getPurchaseTypeFilterValues("purchaseType", tableData, purchaseTypes),
            onFilter: (key, record) => record.purchaseType === key
        },
        {
            title: "Received Date",
            dataIndex: 'receivedDate',
            sorter: true,
            render: (text) => displayFormattedDate(text)
        },
        {
            title: "Garden / Auction",
            dataIndex: 'gardenAuctionName',
            sorter: true,
            filters: getFilterValues("gardenAuctionName", tableData),
            onFilter: (key, record) => record.gardenAuctionName == key
        },
        {
            title: "Quantity (bags)",
            dataIndex: 'numberOfBags',
            sorter: true
        },
        {
            title: "Quantity (kg)",
            dataIndex: 'quantityInKg',
            sorter: true
        },
        {
            title: "Tea Grade",
            dataIndex: 'gardenTeaGrade',
            sorter: true,
            render: (text) => !isEmpty(text) && teaGrades.find(type => type.id === text).teaGrade,
            filters: getTeaGradeFilterValues("gardenTeaGrade", tableData, teaGrades),
            onFilter: (key, record) => record.gardenTeaGrade == key
        },
        {
            title: "Rate",
            dataIndex: 'rate',
            sorter: true
        },
        {
            title: "Amount",
            dataIndex: 'amount',
            sorter: true
        },
        {
            title: "GST",
            dataIndex: 'gst',
            sorter: true
        },
        {
            title: "Transport",
            dataIndex: 'transport',
            sorter: true
        },
        {
            title: "Total Amount",
            dataIndex: 'totalAmount',
            sorter: true,
            render: (text, record) => calculateTotalAmount(record)
        },
        {
            title: "Invoice Number",
            dataIndex: 'invoiceNumber',
            sorter: true
        },
        {
            title: "Purchase Date",
            dataIndex: 'purchaseDate',
            sorter: true,
            render: (text) => displayFormattedDate(text)
        },
        {
            title: "Quality Grade",
            dataIndex: 'qualityTeaGrade',
            sorter: true,
            render: (text) => !isEmpty(text) && grades.find(type => type.id === text).grade,
            filters: getQualityGradeFilterValues("qualityTeaGrade", tableData, grades),
            onFilter: (key, record) => record.qualityTeaGrade == key,
            width: 100
        }
    ];
    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setNonFilteredTableData(data);
        setTableData(data);
    }, [responseData]);

    const searchReport = () => {
        form.validateFields().then(values => {
            const fromDate = values.dateRange[0];
            const toDate = values.dateRange[1];
            const filteredData = nonFilteredTableData.filter((item) =>
                moment(item.receivedDate).add(1, "days") >= fromDate && moment(item.receivedDate) <= toDate);
            setTableData(filteredData);
        }
        ).catch();
    }

    const refersh = () => {
        form.resetFields();
        getRequest();
    }

    return <Space direction='vertical' style={{ width: "100%" }}>
        <Form form={form} layout="vertical">
            <Row gutter={32}>
                <Col span={10}>
                    <Form.Item name="dateRange" label="Date Range" rules={[{
                        required: true,
                        message: "Start and End Date is required."
                    }]}>
                        <RangePicker
                            allowEmpty={['false', 'false']} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label=" ">
                        <Space>
                            <Button type='primary' size='middle' onClick={searchReport}>Search</Button>
                            <Button type='ghost' size='middle' onClick={refersh}>Clear Search</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <TableComponent columns={columns} tableData={tableData} loading={responseData.isLoading} />
    </Space>
}

export default RawMaterialPurchaseReport;