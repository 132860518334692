import { Button, Col, Result, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import {
  getBrandFilters,
  getBrandPackagingName,
  getClientConfig,
  getStockInNumberOfBags,
} from "../../generalFunctions";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import AddTeaDispatch from "./AddTeaDispatch";
import AddTeaProcessing from "./AddTeaProcessing";
import DispatchedTeaEntries from "./DispatchedTeaEntries";
import ProcessedTeaEntries from "./ProcessedTeaEntries";

function TeaInventoryTable() {
  const { bagConversion, brandPackagingQuantity, sachetSetting } =
    getClientConfig();
  const getFilteredRecords = (key, record) => {
    const brandPackagingQuantityRecords = brandPackagingQuantity.filter(
      (type) => type.brandId === key
    );
    let keys = [];
    for (let i = 0; i < brandPackagingQuantityRecords.length; i++) {
      keys.push(brandPackagingQuantityRecords[i].id);
    }
    if (keys.includes(record.brandPackagingQuantityId)) {
      return true;
    }
  };
  const columns = [
    {
      title: "Packaging ",
      dataIndex: "brandPackagingQuantityId",
      render: (text, record) => getBrandPackagingName(record),
      filters: getBrandFilters(),
      onFilter: (key, record) => getFilteredRecords(key, record),
    },
    {
      title: "Stock (bags)",
      dataIndex: "stock",
      render: (text, record) =>
        getStockInNumberOfBags(
          text,
          record.packagingGrammage,
          record.brandPackagingQuantityId
        ),
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => viewProcessedEntries(record)}>
            View Processed Entries
          </Button>
          <Button size="small" onClick={() => viewDispatchedEntries(record)}>
            View Dispatched Entries
          </Button>
        </Space>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    "/teaInventory",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [visibleProcessedEntries, setVisibleProcessedEntries] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [visibleDisptach, setVisibleDisptach] = useState(false);
  const [visibleDispatchedEntries, setVisibleDispatchedEntries] =
    useState(false);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  const handleAddTeaProcessing = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    isUpdated === true && getRequest();
  };

  const handleAddTeaDispatch = (isUpdated) => {
    const flag = visibleDisptach ? false : true;
    setVisibleDisptach(flag);
    isUpdated === true && getRequest();
  };

  const viewProcessedEntries = (record) => {
    setVisibleProcessedEntries(true);
    setSelectedRecord(record);
  };

  const viewDispatchedEntries = (record) => {
    setVisibleDispatchedEntries(true);
    setSelectedRecord(record);
  };

  const handleProcessedEntries = (isUpdated) => {
    const flag = visibleProcessedEntries ? false : true;
    setVisibleProcessedEntries(flag);
    isUpdated === true && getRequest();
  };

  const handleDispatchedEntries = (isUpdated) => {
    const flag = visibleDispatchedEntries ? false : true;
    setVisibleDispatchedEntries(flag);
    isUpdated === true && getRequest();
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Typography.Title level={5}>Tea Inventory</Typography.Title>
        </Col>
        <Col span={10}></Col>
        <Col span={8}>
          <Space style={{ marginBottom: 10, float: "right" }}>
            <Button type="primary" onClick={handleAddTeaProcessing}>
              Packaging Production
            </Button>
            <Button
              type="primary"
              onClick={handleAddTeaDispatch}
              disabled={responseData.data === BLANK_VALUE}
            >
              Dispatch Stock
            </Button>
          </Space>
        </Col>
      </Row>
      <>
        {bagConversion !== BLANK_VALUE || sachetSetting !== BLANK_VALUE ? (
          <TableComponent
            columns={columns}
            tableData={tableData}
            loading={responseData.isLoading}
          />
        ) : (
          <Result
            status="error"
            title="Please add Bag Conversion or Sachet Settings."
          />
        )}
      </>
      {visible && (
        <AddTeaProcessing visible={visible} close={handleAddTeaProcessing} />
      )}
      {visibleProcessedEntries && (
        <ProcessedTeaEntries
          visible={visibleProcessedEntries}
          brandPackagingQuantityId={
            selectedRecord && selectedRecord.brandPackagingQuantityId
          }
          close={handleProcessedEntries}
        />
      )}

      {visibleDisptach && (
        <AddTeaDispatch
          visible={visibleDisptach}
          close={handleAddTeaDispatch}
        />
      )}
      {visibleDispatchedEntries && (
        <DispatchedTeaEntries
          visible={visibleDispatchedEntries}
          brandPackagingQuantityId={
            selectedRecord && selectedRecord.brandPackagingQuantityId
          }
          close={handleDispatchedEntries}
        />
      )}
    </>
  );
}

export default TeaInventoryTable;
