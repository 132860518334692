import { Button, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import {
  getBrandFilters,
  getClientConfig,
  getFilteredBrandRecords,
} from "../../../generalFunctions";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddInwardBagConversion from "./AddInwardBagConversion";

const InwardBagConversionTable = (props) => {
  const columns = [
    {
      title: "Brand Name",
      dataIndex: "brandId",
      render: (text) => getBrandName(text),
      filters: getBrandFilters(),
      onFilter: (key, record) => getFilteredBrandRecords(key, record),
    },
    {
      title: "Number Of Kgs Per Bag",
      dataIndex: "numberOfKgsPerBag",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",

      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    "/config/inwardBagConversion",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const { brands } = getClientConfig();
  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  const handleAddInwardBagConversion = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const handleEditInwardBagConversion = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      props.refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const getBrandName = (id) => {
    return brands.find((type) => type.id === id).brand; // brand name
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Typography.Title level={5}>Inward Bag Conversions</Typography.Title>
        </Col>
        <Col span={18}>
          <Space style={{ marginBottom: 10, float: "right" }}>
            <Button type="primary" onClick={handleAddInwardBagConversion}>
              Add Inward Bag Conversion
            </Button>
          </Space>
        </Col>
      </Row>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
      />
      {visible && (
        <AddInwardBagConversion
          visible={visible}
          close={handleAddInwardBagConversion}
        />
      )}
      {visibleEdit && (
        <AddInwardBagConversion
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditInwardBagConversion}
        />
      )}
    </>
  );
};

export default InwardBagConversionTable;
