import moment from 'moment';
import { BLANK_VALUE } from './constants';

export const formatDate = (date) => {
    return date ? moment(date).format("MM-DD-YYYY") : null;
}

export const formatDateToMoment = (date) => {
    return date ? moment(date) : null;
}

export function replaceNull(jsonObject, replaceValue = BLANK_VALUE) {
    const replacedObject = (key, value) =>
        String(value) === "null" || String(value) === "undefined" || value === "" ? replaceValue : value;
    return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}

export function replaceBlankValueToUndefined(jsonObject) {
    const replacedObject = (key, value) =>
        isEmpty(value) ? undefined : value;
    return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}

export function replaceUndefinedToNull(jsonObject, replaceValue = null) {
    const replacedObject = (key, value) =>
        String(value) === "undefined" ? replaceValue : value;
    return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}


export const displayFormattedDate = (date) => {
    return date ? moment(date).format("DD-MMM-YYYY") : null;
}

export const setDataToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const getDataFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const getClientConfig = () => {
    return JSON.parse(localStorage.getItem("clientConfig"));
}

export const getFilterValues = (columnKey, records) => {
    if (records && records != BLANK_VALUE) {
        let filterOptions = [];
        //records.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        const options = [...new Set(records.map(record => record[columnKey]))];
        filterOptions = options.map(option => { return { value: option, text: option } });
        return filterOptions;
    }
}

export const getPurchaseTypeFilterValues = (columnKey, records, purchaseTypes) => {
    if (records && records != BLANK_VALUE) {
        let filterOptions = [];
        const options = [...new Set(records.map(record => record[columnKey]))];
        options.forEach(option => {
            if (!isEmpty(option)) {
                filterOptions.push({ value: option, text: purchaseTypes.find(type => type.id === option).purchaseType })
            }
        });
        return filterOptions;
    }
}

export const getTeaGradeFilterValues = (columnKey, records, teaGrades) => {
    if (records && records != BLANK_VALUE) {
        let filterOptions = [];
        const options = [...new Set(records.map(record => record[columnKey]))];
        options.forEach(option => {
            if (!isEmpty(option)) {
                filterOptions.push({ value: option, text: teaGrades.find(type => type.id === option).teaGrade })
            }
        });
        return filterOptions;
    }
}

export const getQualityGradeFilterValues = (columnKey, records, grades) => {
    if (records && records != BLANK_VALUE) {
        let filterOptions = [];
        const options = [...new Set(records.map(record => record[columnKey]))];
        options.forEach(option => {
            if (!isEmpty(option)) {
                filterOptions.push({ value: option, text: grades.find(type => type.id === option).grade })
            }
        });
        return filterOptions;
    }
}

export const isEmpty = (value) => {
    if (value === null || value === undefined || value === BLANK_VALUE) {
        return true;
    }
    return false;
}

export const getPackagingByBrandPackagingQuantityId = (brandPackagingQuantityId) => {
    const { brandPackagingQuantity } = getClientConfig();
    let brandPackagingQuantityRecord = brandPackagingQuantity.find(
        (type) => type.id === brandPackagingQuantityId
    );
    return (
        brandPackagingQuantityRecord.brand + // brand name
        " - " +
        brandPackagingQuantityRecord.typeOfPackaging +
        " - " +
        brandPackagingQuantityRecord.packagingGrammage +
        " grams"
    );
};

export const getPackagingUnit = (packagingMaterialListId) => {
    const { packagingMaterialList } = getClientConfig();
    const packagingMaterialListRecord = packagingMaterialList.find(type => type.id === packagingMaterialListId);
    return packagingMaterialListRecord.unit;
}

export const calculateTotalAmount = (record) => {
    return record.amount + record.gst + (!isEmpty(record.transport) ? record.transport : 0);
}

export const sumOfArrayObjectValuesWithSameKey = (objArr) => {
    return objArr.reduce((accumulator, cur) => {
        let type = cur.type;
        let found = accumulator.find(elem => elem.type === type)
        if (found) found.value += cur.value;
        else accumulator.push(cur);
        return accumulator;
    }, []);
}

export const getBrandFilters = () => {
    const { brands } = getClientConfig();
    const filter = [];
    for (let i = 0; i < brands.length; i++) {
        filter.push({ text: brands[i].brand, value: brands[i].id });
    }
    return filter;
};

export const getFilteredBrandRecords = (key, record) => {
    if (key === record.brandId) return true;
};

export const getStockInNumberOfBags = (
    stock,
    packagingGrammage,
    brandPackagingQuantityId
) => {
    const { bagConversion, sachetSetting } = getClientConfig();
    var bags = 0;
    if (!isEmpty(bagConversion)) {
        const bagConversionRecord = bagConversion.find(
            (type) => type.brandPackagingQuantityId === brandPackagingQuantityId
        );

        if (!isEmpty(bagConversionRecord)) {
            bags =
                (stock * packagingGrammage) /
                1000 /
                bagConversionRecord.numberOfKgsPerBag;
        }
    }

    if (!isEmpty(sachetSetting)) {
        const sachetSettingRecord = sachetSetting.find(
            (type) => type.brandPackagingQuantityId === brandPackagingQuantityId
        );

        if (!isEmpty(sachetSettingRecord)) {
            var bags = 0;
            var pouchesInPuda = sachetSettingRecord.pouchesInPuda;
            var extraPouchesInPuda = !isEmpty(
                sachetSettingRecord.extraPouchesInPuda
            )
                ? sachetSettingRecord.extraPouchesInPuda
                : 0;
            var pudeInBag = sachetSettingRecord.pudeInBag;
            var extraPouchesInBag = !isEmpty(
                sachetSettingRecord.extraPouchesInBag
            )
                ? sachetSettingRecord.extraPouchesInBag
                : 0;


            var packetsInOneBag =
                (pouchesInPuda + extraPouchesInPuda) * pudeInBag +
                extraPouchesInBag;

            bags = stock / packetsInOneBag;
        }
    }
    return bags.toFixed(2);
};

export const getBrandPackagingName = (record) => {
    return (
        record.brand +
        " - " +
        record.typeOfPackaging +
        " - " +
        record.packagingGrammage +
        " grams"
    );
};

export const getPackagingMaterialName = (id) => {
    const { packagingMaterialList } = getClientConfig();
    const packagingMaterialListRecord = packagingMaterialList.find(type => type.id === id);
    return packagingMaterialListRecord.packagingMaterial;
}

export const getPackagingInvetoryBalance = (value, record) => {
    const { packagingInfo } = getClientConfig();
    var packagingInfoRecord = packagingInfo.find(type => type.packagingMaterialListId
        === record.packagingMaterialListId);
    if (!isEmpty(packagingInfoRecord)) {
        let balanceInKgs = value / packagingInfoRecord.numberOfPacketsPerKg

        return balanceInKgs.toFixed(2) + " " + "Kg"
    }
    return value
}
