import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  PlusSquareOutlined,
  CoffeeOutlined,
  ContainerOutlined,
  FileTextOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import RawMaterialTable from "../components/raw material/RawMaterialTable";
import BrandTable from "../components/config/brand/BrandTable";
import BrandPackagingQuantityTable from "../components/config/brand packaging quantity/BrandPackagingQuantityTable";
import PricingTable from "./config/pricing/PricingTable";
import PackagingInfoTable from "./config/packaging info/PackagingInfoTable";
import BagConversionTable from "./config/bag conversion/BagConversionTable";
import { API_METHODS } from "../constants";
import useApi from "../hooks/useApi";
import { setDataToLocalStorage } from "../generalFunctions";
import PackagingMaterialTable from "./packaging/PackagingMaterialTable";
import ReportTable from "./reports/ReportTable";
import TeaInventoryTable from "./tea processing/TeaInventoryTable";
import BrandPackagingTypeMappingTable from "./config/brand packagingtype mapping/BrandPackagingTypeMappingTable";
import Dashboard from "./dashboard/Dashboard";
import logo from '../assests/images/logo1.png';
import UserTable from "./config/user/UserTable";
import GradeTable from "./config/grade/GradeTable";
import TeaGradeTable from "./config/tea grade/TeaGradeTable";
import PackagingMaterialListTable from "./config/packaging material list/PackagingMaterialListTable";
import PurchaseTypeTable from "./config/purchase type/PurchaseTypeTable";
import RoleTable from "./config/role/RoleTable";
import TypeOfPackagingTable from "./config/type of packaging/TypeOfPackagingTable";
import InwardBagConversionTable from "./config/inward bag conversion/InwardBagConversionTable";
import InwardBagsTable from "./tea processing/InwardBagsTable";
import SachetSettingTable from "./config/5-10Setting/SachetSettingTable";


const { Header, Sider, Content } = Layout;

function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [clientConfig, getClientConfig] = useApi("/config", null, API_METHODS.GET);

  useEffect(() => {
    getClientConfig();
  }, []);

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
      if (location.pathname === "/inventory") {
        navigate("/inventory/dashboard");
      }
    }
  }, [clientConfig]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logoutClicked = () => {
    //TODO: api call for token cancellation
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("clientConfig");
    navigate("/");
  };

  const isAdmin = () => {
    if (JSON.parse(localStorage.getItem("user")).role === 1) return true
    else return false
  }

  const refreshClientConfig = () => {
    getClientConfig();
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <img style={{ height: 36, width: "96%" }} src={logo} />
        </div>
        <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link to="dashboard">Dashboard</Link>
          </Menu.Item>
          {isAdmin() && <> <Menu.Item key="2" icon={<PlusSquareOutlined />}>
            <Link to="rawMaterial">Raw Tea Material</Link>
          </Menu.Item>
            <Menu.Item key="4" icon={<ContainerOutlined />}>
              <Link to='packagingMaterial' >Packaging Material</Link>
            </Menu.Item>
            <Menu.SubMenu key="3" icon={<CoffeeOutlined />} title="Tea Inventory">
              <Menu.Item key="301">
                <Link to="inwardBags">Loose Inward Bags</Link>
              </Menu.Item>
              <Menu.Item key="300">
                <Link to="teaInventory">Tea Inventory</Link>
              </Menu.Item>
            </Menu.SubMenu>

          </>}
          <Menu.Item key="5" icon={<FileTextOutlined />}>
            <Link to="reports">Reports</Link>
          </Menu.Item>
          {isAdmin() && <> <Menu.SubMenu key="6" icon={<SettingOutlined />} title="Settings">
            <Menu.Item key="600">
              <Link to='user' >User</Link>
            </Menu.Item>
            <Menu.Item key="601">
              <Link to='brand' >Brand</Link>
            </Menu.Item>
            <Menu.Item key="602">
              <Link to='brandPackagingQuantity'>Brand Packaging Quantity</Link>
            </Menu.Item>
            <Menu.Item key="603">
              <Link to='rate'>Rate</Link>
            </Menu.Item>
            <Menu.Item key="606">
              <Link to='brandPackagingTypeMapping'>Brand-Packaging Type Relation</Link>
            </Menu.Item>
            <Menu.Item key="604">
              <Link to='packagingInfo'>Packaging Info</Link>
            </Menu.Item>
            <Menu.Item key="609">
              <Link to='packagingMaterialList'>Packaging Material List</Link>
            </Menu.Item>
            <Menu.Item key="612">
              <Link to='typeOfPackaging'>Type Of Packaging</Link>
            </Menu.Item>
            <Menu.Item key="613">
              <Link to='inwardBagConversion'>Inward Bag Conversion</Link>
            </Menu.Item>
            <Menu.Item key="605">
              <Link to='bagConversion'>Bag Conversion</Link>
            </Menu.Item>
            <Menu.Item key="608">
              <Link to='teaGrade'>Tea Grade</Link>
            </Menu.Item>
            <Menu.Item key="607">
              <Link to='grade' >Grade</Link>
            </Menu.Item>
            <Menu.Item key="610">
              <Link to='purchaseType'>Purchase Type</Link>
            </Menu.Item>
            {/* <Menu.Item key="611">
              <Link to='role'>Role</Link>
            </Menu.Item> */}
            <Menu.Item key="614">
              <Link to='pudaSetting'>5/10 Rs. Setting</Link>
            </Menu.Item>
          </Menu.SubMenu></>}
          <Menu.Item key="7" icon={<LogoutOutlined />} onClick={logoutClicked}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          minHeight: "100vh",
          width: "100%"
        }}
      >
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/rawMaterial" element={<RawMaterialTable />} />
            <Route path="/teaInventory" element={<TeaInventoryTable />} />
            <Route path="/inwardBags" element={<InwardBagsTable />} />
            <Route path="/packagingMaterial" element={<PackagingMaterialTable />} />
            <Route path="/reports" element={<ReportTable />} />
            <Route path="/user" element={<UserTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/brand" element={<BrandTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/brandPackagingQuantity" element={<BrandPackagingQuantityTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/rate" element={<PricingTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/packagingInfo" element={<PackagingInfoTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/bagConversion" element={<BagConversionTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/brandPackagingTypeMapping" element={<BrandPackagingTypeMappingTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/grade" element={<GradeTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/teaGrade" element={<TeaGradeTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/packagingMaterialList" element={<PackagingMaterialListTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/purchaseType" element={<PurchaseTypeTable refreshClientConfig={refreshClientConfig} />} />
            {/* <Route path="/role" element={<RoleTable refreshClientConfig={refreshClientConfig} />} /> */}
            <Route path="/pudaSetting" element={<SachetSettingTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/typeOfPackaging" element={<TypeOfPackagingTable refreshClientConfig={refreshClientConfig} />} />
            <Route path="/inwardBagConversion" element={<InwardBagConversionTable refreshClientConfig={refreshClientConfig} />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
