import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import {
  displayFormattedDate,
  getPackagingByBrandPackagingQuantityId,
} from "../../generalFunctions";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";

function ProcessedTeaEntries(props) {
  const { visible, close, brandPackagingQuantityId } = props;
  const [responseData, getRequest] = useApi(
    `/teaInventory/processedEntries/${brandPackagingQuantityId}`,
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();

  const columns = [
    {
      title: "Processed Bags",
      dataIndex: "processedBags",
      sorter: true,
    },
    {
      title: "Tea Wastage(In KG's)",
      dataIndex: "teaWastage",
      sorter: true,
    },
    // {
    //   title: "Processed Stock",
    //   dataIndex: "processedStock",
    //   sorter: true,
    // },
    {
      title: "Processed Date",
      dataIndex: "processedDate",
      sorter: true,
      render: (text) => displayFormattedDate(text),
    },
  ];

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  return (
    <>
      <Modal
        title={
          "Processed Entries for " +
          getPackagingByBrandPackagingQuantityId(brandPackagingQuantityId)
        }
        visible={visible}
        footer={null}
        width={1000}
        onCancel={close}
      >
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
        />
      </Modal>
    </>
  );
}

export default ProcessedTeaEntries;
